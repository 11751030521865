import { useSelector } from "react-redux";
import { URL } from "../../redux/common/url";
import { useHistory } from "react-router-dom";

const VerticalAddSlider = () => {
  const adBanner: any[] = useSelector((state: any) =>
    state.unversity.adBanner ? state.unversity.adBanner : []
  );

  const history = useHistory();

  const clickhandler = (link: any) => {
    const url =
      link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `https://${link}`;

    // Open the external link in a new tab
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {true &&
        adBanner
          .filter((item) => item.add_type === 3)
          .map((item) => (
            <div style={{ marginTop: "10%" }}>
              <img
                src={URL.API_BASE_URL + item?.images}
                style={{ maxWidth: "100%", cursor: "pointer" }}
                onClick={() => clickhandler(item.ad_hyperlink)}
              />
            </div>
          ))}
    </>
  );
};

export default VerticalAddSlider;
