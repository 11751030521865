import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSeoDetail } from "../../redux/setting/action";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import {
  applyNoAction,
  UniversityAction,
  UniversityCourseAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import Slider from "react-slick";
import { PieChart } from "react-minimal-pie-chart";
import dateFormat from "dateformat";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import UniversitySlider from "./UniversitySlider";
import StarRatings from "react-star-ratings";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { URL } from "../../redux/common/url";
import { toast } from "react-toastify";
import Exam from "../country/Exam";
import { $CombinedState } from "redux";
import moment from "moment";
import ShareModal from "./ShareModal";

function UniversitiesDetails(props: any) {
  const dispatch = useDispatch();
  const id: any = useLocation();
  const lastLocation = useLocation();
  const history = useHistory();
  const [studylevel, setstudylevel] = useState<any>(1);
  const [universityRenkingByYear, setuniversityRenkingByYear] = useState<any>();
  const [readMore, setreadMore] = useState<any>(10);
  const [readMoreUni, setreadMoreUni] = useState<any>(50);
  const { setuniversityDetails } = props;
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [modelData, setmodelData] = useState<any>("");
  const [Marks, setMarks] = useState<any>("");
  const [CourseId, setCourseId] = useState<any[]>([]);
  const [SelectedCourseCount, setSelectedCourseCount] = useState<any[]>();
  const [userCourseLevel, setuserCourseLevel] = useState<any>("");
  const [oneTime, setOneTime] = useState<any>("0");
  const [CurrentLevel, setCurrentLevel] = useState<any>("");
  const [CurrentStreamId, setCurrentStreamId] = useState<any>("");
  const [indexMore, setIndexMore] = useState<any>("");
  const [shown, setShown] = React.useState(false);
  const [course_id_dt, setCourse_id_dt] = useState<any>(props?.course_id_dt);
  const [course_level_dt, setCourse_level_dt] = useState<any>(
    props?.course_level_dt
  );

  const baseurl = URL.API_BASE_URL;
  const uniSlug: any = useParams();
  const urlforShare = window.location.href;
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  var userInterests = localStorage.getItem("userInterest");

  var IsAvailability = false;
  if (userInterests) {
    userInterests = JSON.parse(userInterests);
  }
  if (userInterests) {
    IsAvailability = true;
  }
  /* useEffect(()=> {
    if(props?.course_level_dt){
    }
    if(props?.course_id_dt){
    }
  },[props]) */

  const setEligibility = (marks: any, type = null) => {
    if (marks >= 85) {
      return setMarks(marks);
    } else if (marks < 85 && marks >= 70) {
      return setMarks(marks);
    } else {
      return setMarks(marks);
    }
  };
  $(".eligibility_btn").click(function (k, v) {
    var id = $(this).attr("data-id");
    $("#eligibility_" + id).fadeIn(0);
  });
  // const university: any = useSelector((state: any) =>
  //   state.unversity.universityById ? state.unversity.universityById : []
  // );
  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );

  const applyNowFun: any = useSelector((state: any) =>
    state.applyNow.applyNow ? state.applyNow.applyNow : []
  );
  // if (applyNowFun) {
  //   if (applyNowFun.status) {
  //     $('#__apply_now__thankyou').click();
  //   }
  // }
  const university: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );

  const disLength =
    university?.UniversityDetail?.description?.split(" ").length;
  const openModelPopup = (event, id: any, level: any, streamId: any) => {
    setCurrentLevel(level);
    setCurrentStreamId(streamId);
    var maxChecked = $(".apply-now-checkbox").filter(":checked").length;
    var x = maxChecked + appliedCoursesArray.length;
    if (x > 3) {
      maxChecked = x;
      // CourseId.push(appliedCoursesArray);
      setSelectedCourseCount(maxChecked);
    }
    if (maxChecked <= 3) {
      if ($("#_apply_now_" + id).prop("checked")) {
        CourseId.push(id);
        setSelectedCourseCount(maxChecked);
      } else {
        setSelectedCourseCount(maxChecked);
      }
    } else {
      $("#_apply_now_" + id).attr("checked", false);
      toast.error("You can apply maximum 3 courses.");
    }
    $("#__apply_now_" + id).click();
  };
  const handleApplyNow = (event: any) => {
    var CourseIdFilter = CourseId.filter(function (a) {
      if (!this[a]) {
        this[a] = 1;
        return a;
      }
    }, {});

    if (CourseIdFilter.length > 0) {
      var postData = {
        student_id: user?.id,
        mobile: user?.mobile,
        university_id: id?.state?.university_id
          ? id?.state?.university_id
          : university?.id,
        course_level: userInterests
          ? userInterests[0]?.course_level
          : CurrentLevel,
        stream_id: userInterests
          ? userInterests[0]?.streamData?.streamId
          : CurrentStreamId,
        intake: userInterests ? userInterests[0]?.intake : null,
        course_id: CourseIdFilter,
        objective: userInterests ? userInterests[0].objective?.objective : null,
        country_id: university?.country_id,
        highest_edu: userInterests ? userInterests[0]?.highest_edu : null,
        tests: userInterests ? userInterests[0]?.tests : null,
      };

      dispatch(applyNoAction.applyNow(postData));

      if (user?.id && user?.mobile) {
        dispatch(
          UniversityAction.appliedCoursesFrontendUniStudMain({
            university_id: id?.state?.university_id
              ? id?.state?.university_id
              : university?.id,
            student_id: user?.id,
            mobile: user?.mobile,
          })
        );
      }
    } else {
      toast.error("Please choose at least 1 course.");
    }
  };

  const nearRankuniversity: any = useSelector((state: any) =>
    state.unversity.getrankinguniversity
      ? state.unversity.getrankinguniversity
      : []
  );

  const universityRenking =
    typeof university?.UniversityDetail?.rankings == "string"
      ? JSON.parse(university?.UniversityDetail?.rankings)
      : "";

  const about_uni =
    typeof university?.UniversityDetail?.about_content == "string"
      ? JSON.parse(university?.UniversityDetail?.about_content)
      : "";

  const undergraduate =
    typeof university?.UniversityDetail?.undergraduate == "string"
      ? JSON.parse(university?.UniversityDetail?.undergraduate)
      : "";

  const postgraduate =
    typeof university?.UniversityDetail?.postgraduate == "string"
      ? JSON.parse(university?.UniversityDetail?.postgraduate)
      : "";

  const videoUrl = university.video_url;
  const res = videoUrl ? videoUrl.split("=") : "";
  const embeddedUrl = "https://www.youtube.com/embed/" + res[1];

  // var youtube_video_id = videoUrl.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();

  //   const video_thumbnail = $('<img src="//img.youtube.com/vi/' + youtube_video_id + '/0.jpg">');

  const test_scores =
    typeof university?.UniversityDetail?.test_scores == "string"
      ? JSON.parse(university?.UniversityDetail?.test_scores)
      : "";

  let resultArray: any = {};
  let loopArray: any = test_scores && test_scores;

  for (let x in loopArray) {
    let courseKey = loopArray[x].level_title;
    let obj = Object.assign({}, loopArray[x]);
    delete obj.level_title;
    if (resultArray[courseKey]) {
      resultArray[courseKey].push(obj);
    } else {
      let objToPush = {};
      resultArray[courseKey] = [obj];
    }
  }

  const otherUni = (e: any) => {
    history.push("/university/" + e, {
      university_id: e,
    });
    dispatch(UniversityAction.getUniversityBySlug(e));
    return () => {};
  };

  const keys = ["year"];
  const filtered =
    universityRenking &&
    universityRenking?.filter(
      (
        (s) => (o: any) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k: any) => o[k]).join("|"))
      )(new Set())
    );

  const unifilforyear =
    universityRenking[0]?.year !== undefined ? universityRenking[0]?.year : "";

  useEffect(() => {
    setuniversityRenkingByYear(unifilforyear);
  }, [unifilforyear]);

  const filterRank = universityRenking
    ? universityRenking?.filter((data: any) => {
        return data?.year == universityRenkingByYear;
      })
    : "";

  const universityComment: any[] = useSelector((state: any) =>
    state.unversity.universityComment ? state.unversity.universityComment : []
  );

  const universities: any[] = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );
  const fee_structure =
    typeof university?.UniversityDetail?.fee_structure == "string"
      ? JSON.parse(university?.UniversityDetail?.fee_structure)
      : "";

  const abcsd = (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8462.36210983558!2d75.74645838390298!3d26.903971742376967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db499daa64d33%3A0xdf2938c5f09a5ece!2sAkshardham%20Temple!5e0!3m2!1sen!2sin!4v1637839715734!5m2!1sen!2sin"
      width="600"
      height="450"
      loading="lazy"
    ></iframe>
  );
  // const bcd = abcsd.split(" ")

  const tuitionFee =
    fee_structure && typeof fee_structure == "object"
      ? fee_structure.filter((data: any) => {
          return data.type == "tuitionFee";
        })
      : [];
  const campusFee =
    fee_structure && typeof fee_structure == "object"
      ? fee_structure.filter((data: any) => {
          return data.type == "campusFee";
        })
      : [];

  const uni = (e: any, data: any) => {
    setuniversityDetails(data.data);
  };

  const male =
    (university?.male_enrolled /
      (university?.male_enrolled + university?.female_enrolled)) *
    100;

  const female = 100 - male;

  const levelofstudy = (e: any) => {
    setstudylevel(e);
    if (userInterests) setuserCourseLevel(e);
  };

  const universityCourse: any = useSelector((state: any) =>
    state.universitycourse.universitycourse
      ? state.universitycourse.universitycourse
      : []
  );
  const appliedCourses: any = useSelector((state: any) =>
    state.unversity.appliedCoursesFrontendUniStud
      ? state.unversity.appliedCoursesFrontendUniStud
      : []
  );
  var appliedCoursesArray = [];
  if (appliedCourses.status) {
    appliedCourses?.data?.map((v: any, k: any) => {
      appliedCoursesArray.push(v.course_id);
    });
  }
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  useEffect(() => {
    if (university?.id) {
      if (oneTime == 0) {
        setOneTime(1);
        if (university?.country_id && userInterests != null) {
          setuserCourseLevel(userInterests[0]?.course_level);
          userInterests[0]?.country_id?.map((data: any, i: any) => {
            if (data.countryId == university?.country_id) {
              IsAvailability = true;
            }
          });
        }
      }
      dispatch(
        UniversityAction.getrankinguniversities({
          ranking: university?.ranking ? university?.ranking : "",
          offset: 0,
          limit: 8,
        })
      );
      dispatch(
        UniversityAction.getuniversities({
          search_by: "",
          offset: 0,
          country_id: [university?.country_id],
          limit: 6,
        })
      );
      dispatch(
        UniversityCourseAction.getAllUniCourses({
          UniversityId: id?.state?.university_id
            ? id?.state?.university_id
            : university?.id,
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
          CourseId: course_id_dt ? [course_id_dt] : "",
        })
      );

      dispatch(
        UniversityAction.getAllUniversitiesComment({
          university_id: id?.state?.university_id,
        })
      );
      if (user?.id && user?.mobile) {
        dispatch(
          UniversityAction.appliedCoursesFrontendUniStudMain({
            university_id: id?.state?.university_id
              ? id?.state?.university_id
              : university?.id,
            student_id: user?.id,
            mobile: user?.mobile,
          })
        );
      }
    }
  }, [university]);
  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
  }, []);

  const uniCourse = universityCourse;
  // const uniCourse =
  //   universityCourse &&
  //   universityCourse.filter((data: any) => {
  //     return data.university_id === id?.state?.university_id ? id?.state?.university_id : university?.id;
  //     // return data.university_id === university.id;
  //   });
  const CourseStudyLavel = uniCourse; /* &&
    uniCourse.filter((data: any) => {
      if(course_level_dt) {
        return data.course_level == course_level_dt;
      }
      else if (userCourseLevel) {
        return data.course_level == userCourseLevel;
      } else {
        return data.course_level == studylevel;
      }
  }); */
  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/counsellor-and-mentor");
    }
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      window.location.href = "/login";
    } else {
      window.location.href = "/";
    }
  };

  var singleslider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  var slidercont = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  var sayslider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var rankingslider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleUniFollow = (e: any) => {
    e.stopPropagation();
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
    }
  };
  const seo: any = useSelector((state: any) => state.setting.seo);
  useEffect(() => {
    window?.scrollTo(0, 0);
  });
  return (
    <div>
      {/* <UniversitySlider/> */}
      {/* <!-- /Page Header --> */}
      <ShareModal
        open={open}
        onCloseModal={onCloseModal}
        urlforShare={urlforShare}
      />

      <section className="uni-list-page uni-list-page-1">
        {/* <h1>{getSeoDetail?.og_title}</h1> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-pull-10 order-lg-2">
              <div className="uni-sidebar">
                {/* <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center p-2">
                  <img src="/assets/img/university-icon.svg" alt="" />
                  <p>
                    <small>are you interested?</small>
                  </p>
                  <button
                    type="button"
                    className="btn simple"
                    onClick={handleFreeCoaching}
                  >
                    get free counselling
                  </button>
                </div>
                {/* <!-- END / WIDGET -->




                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center">
                  <div className="headgreenbg text-center">offer for you</div>
                  <div className="singleslider">
                    <Slider {...singleslider}>
                      {getOFferAndScholar &&
                        getOFferAndScholar?.map((data: any, i: any) => {
                          if (data?.type == "Offers")
                            return (
                              <div className="offers-item">
                                <figure className="feat-text">
                                  <img
                                    className="scale"
                                    src={baseurl + data?.coupon_icon}
                                  />
                                </figure>
                                <figcaption className="content-box">
                                  <a
                                    onClick={(e) => {
                                      data?.type == "Offers"
                                        ? opneModel(data?.id)
                                        : history.push(
                                            "/scholarshipdetails/" +
                                              data?.sch_slug
                                          );
                                    }}
                                    data-toggle="modal"
                                    data-target="#offer-pop"
                                    className="mt_btn_yellow"
                                  >
                                    view details
                                  </a>
                                  <h3>
                                    Get{" "}
                                    <span>
                                      {data?.coupon_type == 1
                                        ? data?.max_off + "%"
                                        : data?.max_off}{" "}
                                      OFF
                                    </span>{" "}
                                    {data?.coupon_title}
                                  </h3>
                                </figcaption>
                              </div>
                            );
                        })}
                    </Slider>
                  </div>
                </div>
                {/* <!-- END / WIDGET -->
 <!-- WIDGET --> */}
                {/* <div className="widget sidebar-title shade2 radius5 text-center p-2">
                  <p>
                    <small>
                      follow & share this college to get information about
                      admission.
                    </small>
                  </p>
                  <button type="button" className="btn simple wfollow" onClick={(e:any)=> handleUniFollow(e)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.822"
                      height="10.18"
                      viewBox="0 0 12.822 11.18"
                    >
                      <g
                        id="Icon_feather-user-plus"
                        data-name="Icon feather-user-plus"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_6294"
                          data-name="Path 6294"
                          d="M9.561,25.893V24.762A2.208,2.208,0,0,0,7.411,22.5H3.649A2.208,2.208,0,0,0,1.5,24.762v1.131"
                          transform="translate(-1.5 -15.714)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6295"
                          data-name="Path 6295"
                          d="M11.049,6.762A2.208,2.208,0,0,1,8.9,9.025,2.208,2.208,0,0,1,6.75,6.762,2.208,2.208,0,0,1,8.9,4.5,2.208,2.208,0,0,1,11.049,6.762Z"
                          transform="translate(-4.869 -4.5)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6296"
                          data-name="Path 6296"
                          d="M30,12v3.393"
                          transform="translate(-19.79 -9.173)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6297"
                          data-name="Path 6297"
                          d="M28.724,16.5H25.5"
                          transform="translate(-16.902 -11.976)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                      </g>
                    </svg>{" "}
                    Follow
                  </button>{" "}
                  <button type="button" className="btn simple wshare" onClick={onOpenModal}>
                    <i className="fa fa-share-alt"></i> Share
                  </button>
                </div> */}
                {/* <!-- END / WIDGET -->
                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5">
                  <div className="headgreenbg text-center">
                    university in {university?.Country?.country_name}
                    {/* Dubai, UAE */}
                  </div>
                  <ul className="universitein">
                    {universities &&
                      universities?.map((data: any, i: any) => {
                        if (data?.id !== university.id)
                          return (
                            i < 12 && (
                              <li
                                key={i}
                                onClick={(e) => {
                                  otherUni(data?.slug);
                                }}
                              >
                                <div
                                  className="imglogo"
                                  style={{ cursor: "pointer" }}
                                >
                                  {data.university_logo != null &&
                                  data.university_logo ? (
                                    <img
                                      src={baseurl + data.university_logo}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="/assets/img/university-icon.png"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="unitextwidth1">
                                  <p
                                    className="__hover uni-sort-name"
                                    onClick={() => {
                                      history.push("/university/" + data?.slug);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data?.university_name?.length > 16
                                      ? `${data?.university_name.substring(
                                          0,
                                          16
                                        )}...`
                                      : data?.university_name}
                                  </p>
                                  <p
                                    className="__hover  uni-full-name"
                                    onClick={() => {
                                      history.push("/university/" + data?.slug);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data?.university_name}
                                  </p>
                                </div>
                                <div className="unitextwidth2">
                                  <p>
                                    <a>
                                      {data?.rating == null
                                        ? "0"
                                        : data?.rating?.toFixed(1)}
                                    </a>
                                  </p>
                                  <p>
                                    <small>{data?.review_count} review</small>
                                  </p>
                                </div>
                              </li>
                            )
                          );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->
<!-- WIDGET --> */}
                <div
                  className="widget sidebar-title shade2 radius5"
                  style={{
                    display: nearRankuniversity.length > 0 ? "" : "none",
                  }}
                >
                  <div className="headgreenbg text-center">
                    nearest ranked colleges
                  </div>

                  <ul className="universitein">
                    {nearRankuniversity &&
                      nearRankuniversity?.map((data: any, i: any) => {
                        return data?.id !== university.id ? (
                          <li
                            key={i}
                            onClick={(e) => {
                              otherUni(data?.slug);
                            }}
                          >
                            <div
                              className="imglogo"
                              style={{ cursor: "pointer" }}
                            >
                              {data.university_logo != null &&
                              data.university_logo ? (
                                <img
                                  src={baseurl + data.university_logo}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="/assets/img/university-icon.png"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="unitextwidth1">
                              {/* <p>{data?.university_name}</p> */}
                              <p
                                className="__hover uni-sort-name"
                                style={{ cursor: "pointer" }}
                              >
                                {data?.university_name?.length > 16
                                  ? `${data?.university_name.substring(
                                      0,
                                      16
                                    )}...`
                                  : data?.university_name}
                              </p>
                              <p
                                className="__hover uni-full-name"
                                style={{ cursor: "pointer" }}
                              >
                                {data?.university_name}
                              </p>
                              <p>
                                {/* <small>Dubai Knowledge Park,</small> */}
                              </p>
                            </div>
                            <div className="unitextwidth2">
                              <p>
                                <a>
                                  {data?.rating == null
                                    ? "0"
                                    : data?.rating?.toFixed(1)}
                                </a>
                              </p>
                              <p>
                                <small>{data?.review_count} review</small>
                              </p>
                            </div>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-10 col-md-push-2">
              {seo?.header_content && (
                <div className="about-uni-text shade1">
                  <SeoHeaderContent />
                </div>
              )}
              <div className="about-uni-text shade1">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <h5>
                      {university?.university_name
                        ? university?.university_name + " "
                        : " "}

                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                    </h5>
                  </div>
                  <div className="col-lg-6 text-right coun-flag1">
                    <img
                      className="country_flag"
                      src={baseurl + `/${university?.Country?.flag}`}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12 __mr-10">
                    {university?.university_logo && (
                      <img
                        className="uniLogody"
                        src={baseurl + `/${university?.university_logo}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-12 __mr-10">
                    {university?.UniversityDetail?.description ? (
                      // <p> { university?.UniversityDetail?.description}  </p>
                      university?.UniversityDetail?.description?.split(" ")
                        .length > readMoreUni ? (
                        <>
                          <p
                            className="editor_css"
                            dangerouslySetInnerHTML={{
                              __html: university?.UniversityDetail?.description
                                ?.split(" ")
                                .splice(0, 50)
                                .join(" "),
                            }}
                          ></p>
                          <a
                            onClick={() =>
                              setreadMoreUni(disLength && disLength + 5)
                            }
                          >
                            read more
                          </a>
                        </>
                      ) : (
                        <p
                          className="editor_css"
                          dangerouslySetInnerHTML={{
                            __html: university?.UniversityDetail?.description,
                          }}
                        ></p>
                      )
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>

              <div className="course-offer-uni shade1">
                <div className="row">
                  <div className="col-12">
                    <div className="headingmains text-center">
                      <h2 className="titlewithline uniDetailsH2 mt-0">
                        Course offered by
                        {/* Emirates Aviation University */}
                        {university?.university_name
                          ? " " + university?.university_name
                          : ""}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33.333"
                            height="33.667"
                            viewBox="0 0 33.333 33.667"
                          >
                            <path
                              id="Icon_material-local-offer"
                              data-name="Icon material-local-offer"
                              d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                              transform="translate(-3 -3)"
                              fill="#fbb415"
                            ></path>
                          </svg>
                        </span>
                      </h2>
                    </div>
                    <div className="all-center sub-section pb-0 pt-0">
                      <div className="lavelbox text-left">
                        <b>level of study</b>
                        {(() => {
                          return (
                            <>
                              {" "}
                              <input
                                type="radio"
                                name="RadioGroup1"
                                value={1}
                                onClick={(e: any) => {
                                  levelofstudy(e.target.value);
                                  if (course_level_dt) {
                                    setCourse_level_dt(1);
                                  }
                                }}
                                id="RadioGroup1_0"
                                defaultChecked={
                                  course_level_dt == 1
                                    ? true
                                    : userCourseLevel
                                    ? userCourseLevel == 1
                                      ? true
                                      : false
                                    : true
                                }
                                style={{
                                  display: userCourseLevel
                                    ? userCourseLevel == 1
                                      ? "none"
                                      : "none"
                                    : "inline-block",
                                }}
                              />
                              <label
                                htmlFor="RadioGroup1_0"
                                style={{
                                  display: userCourseLevel
                                    ? userCourseLevel == 1
                                      ? "inline-block"
                                      : "none"
                                    : "inline-block",
                                }}
                              >
                                {" "}
                                bachelor's{" "}
                              </label>
                            </>
                          );
                        })()}
                        {(() => {
                          return (
                            <>
                              {" "}
                              <input
                                type="radio"
                                name="RadioGroup1"
                                value={2}
                                onClick={(e: any) => {
                                  levelofstudy(e.target.value);
                                  if (course_level_dt) {
                                    setCourse_level_dt(2);
                                  }
                                }}
                                id="RadioGroup1_1"
                                defaultChecked={
                                  course_level_dt == 2
                                    ? true
                                    : userCourseLevel == 2
                                    ? true
                                    : false
                                }
                                style={{
                                  display: userCourseLevel
                                    ? userCourseLevel == 2
                                      ? "none"
                                      : "none"
                                    : "inline-block",
                                }}
                              />
                              <label
                                htmlFor="RadioGroup1_1"
                                style={{
                                  display: userCourseLevel
                                    ? userCourseLevel == 2
                                      ? "inline-block"
                                      : "none"
                                    : "inline-block",
                                }}
                              >
                                {" "}
                                master's
                              </label>
                            </>
                          );
                        })()}
                      </div>
                      {CourseStudyLavel && CourseStudyLavel?.length == 0 ? (
                        <div className="nodataFound">
                          <img src="/assets/img/noDataFound.jpeg" alt="" />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {CourseStudyLavel &&
                          CourseStudyLavel?.map((data: any, index: any) => {
                            let date =
                              data?.application_last_date != "0000-00-00" &&
                              data?.application_last_date != null &&
                              data?.application_last_date != "Invalid date"
                                ? dateFormat(
                                    data?.application_last_date,
                                    "d-mmm-yyyy"
                                  )
                                : "N/A";
                            if (course_level_dt) {
                              var isLevel = course_level_dt;
                            } else if (userCourseLevel) {
                              var isLevel = userCourseLevel;
                            } else {
                              var isLevel = studylevel;
                            }
                            return (
                              isLevel == data?.course_level &&
                              index < 4 && (
                                <div
                                  course_level={data?.course_level}
                                  className="col-md-6"
                                  key={index}
                                >
                                  <div className="studywrp shade1">
                                    <div className="bgstudy">
                                      <div className="row ">
                                        <div className="col-sm-12 col-lg-7 text-left">
                                          <h6>
                                            {data?.university_course} <br />
                                            {data?.course_type == 1
                                              ? "full time"
                                              : data?.course_type == 2
                                              ? "part time"
                                              : data?.course_type == 3
                                              ? "weekend"
                                              : data?.course_type == 4
                                              ? "other"
                                              : ""}
                                          </h6>
                                        </div>
                                        <div className="col-sm-12 col-lg-5">
                                          <h5>
                                            <span>
                                              {data?.fee_currency_code}
                                            </span>{" "}
                                            {data?.first_yr_fee
                                              ? data?.first_yr_fee + "/year"
                                              : ""}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="detailstudy">
                                      <div className="row">
                                        <div className="col-sm-12 col-lg-12 text-left">
                                          <table
                                            width="100%"
                                            style={{ border: "0" }}
                                            cellSpacing="0"
                                            cellPadding="0"
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    style={{ border: "0" }}
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td rowSpan={2}>
                                                          <img
                                                            src="/assets/img/time.png"
                                                            alt=""
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          <b>
                                                            {data?.duration}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            course&nbsp;duration
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    style={{ border: "0" }}
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td rowSpan={2}>
                                                          <img
                                                            src="/assets/img/date.png"
                                                            alt=""
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          <b>{date}</b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            application&nbsp;deadline
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    style={{ border: "0" }}
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <tbody className="course__">
                                                      <tr>
                                                        <td rowSpan={2}>
                                                          <img
                                                            src="/assets/img/library-building.png"
                                                            alt=""
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          {data?.entry_requirements ? (
                                                            indexMore ===
                                                              data?.id &&
                                                            data?.entry_requirements?.split(
                                                              " "
                                                            ).length <
                                                              readMore ? (
                                                              <p className="course__font">
                                                                {
                                                                  data?.entry_requirements
                                                                }
                                                              </p>
                                                            ) : (
                                                              <p className="course__font">
                                                                {" "}
                                                                {data?.entry_requirements
                                                                  ?.split(" ")
                                                                  .splice(0, 5)
                                                                  .join(" ")}
                                                                .{" "}
                                                                <a
                                                                  className="read-more-new"
                                                                  href="#"
                                                                  onClick={(
                                                                    e: any
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    setreadMore(
                                                                      data?.entry_requirements?.split(
                                                                        " "
                                                                      )
                                                                        .length &&
                                                                        data?.entry_requirements?.split(
                                                                          " "
                                                                        )
                                                                          .length +
                                                                          1
                                                                    );
                                                                    setIndexMore(
                                                                      data?.id
                                                                    );
                                                                  }}
                                                                >
                                                                  read more
                                                                </a>
                                                              </p>
                                                            )
                                                          ) : (
                                                            <p></p>
                                                          )}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            entry&nbsp;requirement
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <div className="examscore">
                                            exam scores &nbsp; IELTS{" "}
                                            <b>
                                              {data?.iselts_score
                                                ? data?.iselts_score
                                                : 0}
                                            </b>{" "}
                                            | TOEFL{" "}
                                            <b>
                                              {data?.toefl_scrore
                                                ? data?.toefl_scrore
                                                : 0}
                                            </b>{" "}
                                            | PTE{" "}
                                            <b>
                                              {data?.pte_score
                                                ? data?.pte_score
                                                : 0}
                                            </b>
                                          </div>
                                          <div className="row">
                                            <div className="col-6">
                                              <div className="option">
                                                <span>options available</span>
                                                M.B.A Entrepreneurship
                                              </div>
                                            </div>
                                            <div className="col-6">
                                              {(() => {
                                                var obtainMarks = userInterests
                                                  ? userInterests[0]
                                                      ?.highest_edu
                                                      ?.Achieved_Or_Expected_marks
                                                  : 0;
                                                var resultType = userInterests
                                                  ? userInterests[0]
                                                      ?.highest_edu?.resultType
                                                  : null;
                                                if (resultType == "CGPA") {
                                                  obtainMarks =
                                                    obtainMarks * 10;
                                                }
                                                if (resultType == "Grade") {
                                                  if (
                                                    obtainMarks.toLowerCase() ==
                                                    "a"
                                                  ) {
                                                    obtainMarks = 85;
                                                  } else if (
                                                    obtainMarks.toLowerCase() ==
                                                    "b"
                                                  ) {
                                                    obtainMarks = 75;
                                                  } else if (
                                                    obtainMarks.toLowerCase() ==
                                                    "c"
                                                  ) {
                                                    obtainMarks = 65;
                                                  } else if (
                                                    obtainMarks.toLowerCase() ==
                                                    "d"
                                                  ) {
                                                    obtainMarks = 55;
                                                  }
                                                }

                                                if (IsAvailability == true) {
                                                  return (
                                                    <div className="option text-right">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="eligibility_btn"
                                                      >
                                                        eligibility :{" "}
                                                        <b>
                                                          {obtainMarks >= 85 ? (
                                                            <strong
                                                              style={{
                                                                color: "green",
                                                              }}
                                                            >
                                                              high
                                                            </strong>
                                                          ) : obtainMarks >=
                                                              70 &&
                                                            obtainMarks < 85 ? (
                                                            <strong
                                                              style={{
                                                                color: "orange",
                                                              }}
                                                            >
                                                              moderate
                                                            </strong>
                                                          ) : (
                                                            <strong
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              low
                                                            </strong>
                                                          )}
                                                        </b>
                                                      </a>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="option text-right">
                                                      {(() => {
                                                        if (user?.id) {
                                                          return (
                                                            <a
                                                              href="/applynow"
                                                              className="checkbtn eligibility_btn"
                                                            >
                                                              check eligibility{" "}
                                                            </a>
                                                          );
                                                        } else {
                                                          return (
                                                            <a
                                                              onClick={
                                                                hendleApplyNow
                                                              }
                                                              className="checkbtn eligibility_btn"
                                                            >
                                                              check eligibility{" "}
                                                            </a>
                                                          );
                                                        }
                                                      })()}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-6">
                                              <div className="option">
                                                Offered by{" "}
                                                <p className="color-green">
                                                  {
                                                    data?.ad_university
                                                      ?.university_name
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            {(() => {
                                              if (IsAvailability == true) {
                                                return (
                                                  <div className="col-6">
                                                    <div className="option text-right">
                                                      <div
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <div
                                                          id={
                                                            "__apply_now_" +
                                                            data?.id
                                                          }
                                                          className="hide"
                                                          data-toggle="modal"
                                                          data-target="#date_foo"
                                                        ></div>
                                                        <input
                                                          onClick={(e) =>
                                                            openModelPopup(
                                                              e,
                                                              data?.id,
                                                              data?.course_level,
                                                              data?.stream_id
                                                            )
                                                          }
                                                          className="apply-now-checkbox"
                                                          style={{
                                                            display:
                                                              appliedCoursesArray.includes(
                                                                data?.id
                                                              )
                                                                ? "none"
                                                                : "",
                                                          }}
                                                          type="checkbox"
                                                          id={
                                                            "_apply_now_" +
                                                            data?.id
                                                          }
                                                          disabled={
                                                            appliedCoursesArray.includes(
                                                              data?.id
                                                            ) ||
                                                            appliedCoursesArray.length >=
                                                              3
                                                          }
                                                        />
                                                        <label
                                                          className="apply-now-label"
                                                          for={
                                                            "_apply_now_" +
                                                            data?.id
                                                          }
                                                        >
                                                          {appliedCoursesArray.includes(
                                                            data?.id
                                                          )
                                                            ? "Applied"
                                                            : "Apply Now"}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                      <div
                        className="all-center aos-init"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <a
                          onClick={(e) => {
                            uni(e, { data: "program" });
                          }}
                          className="view-more readbtn"
                        >
                          view all courses
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="about-uni shade1">
                <div className="row">
                  <div className="col-12">
                    <div className="headingmains text-center pb-4">
                      <h2 className="titlewithline uniDetailsH2">
                        {" "}
                        About {university?.university_name + " "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="31.457"
                            viewBox="0 0 35 31.457"
                          >
                            <path
                              id="library-building"
                              d="M34.259,50.572H.741a.741.741,0,0,1,0-1.483H34.259a.741.741,0,0,1,0,1.483ZM32.9,47.192a.741.741,0,0,1-.741-.741V30.066H26.179a.741.741,0,1,1,0-1.483H32.9a.741.741,0,0,1,.741.741s0,.01,0,.015V46.451A.742.742,0,0,1,32.9,47.192Zm-3.7,0h-4.13a.741.741,0,0,1-.741-.741V32.589a.741.741,0,0,1,.741-.741H29.2a.741.741,0,0,1,.741.741V46.451A.741.741,0,0,1,29.2,47.192Zm-3.388-1.483h2.647V42.572H25.815Zm0-4.621h2.647V37.951H25.815Zm0-4.621h2.647V33.33H25.815Zm-4.51,10.724a.741.741,0,0,1-.741-.741V37.1H14.436v9.347a.741.741,0,1,1-1.483,0V36.363a.742.742,0,0,1,.864-.731.743.743,0,0,1,.123-.01h7.118a.753.753,0,0,1,.123.01.742.742,0,0,1,.864.731V46.451A.742.742,0,0,1,21.305,47.192Zm-11.379,0H5.8a.741.741,0,0,1-.741-.741V32.589a.741.741,0,0,1,.741-.741h4.13a.741.741,0,0,1,.741.741V46.451A.741.741,0,0,1,9.926,47.192ZM6.538,45.709H9.185V42.572H6.538Zm0-4.621H9.185V37.951H6.538Zm0-4.621H9.185V33.33H6.538ZM2.1,47.192a.741.741,0,0,1-.741-.741V29.341c0-.005,0-.011,0-.016a.741.741,0,0,1,.741-.741h6.72a.741.741,0,0,1,0,1.483H2.843V46.451A.741.741,0,0,1,2.1,47.192Zm15.4-3.928a.741.741,0,0,1-.741-.741V41.077a.741.741,0,1,1,1.483,0v1.446A.741.741,0,0,1,17.5,43.264Zm-.085-9.906a7.13,7.13,0,0,1-7.122-7.122v-6.38a.741.741,0,0,1,.741-.741H23.8a.741.741,0,0,1,.741.741v6.38A7.13,7.13,0,0,1,17.415,33.359ZM11.776,20.6v5.639a5.639,5.639,0,1,0,11.278,0V20.6ZM17.5,29.323h0a.739.739,0,0,1-.191-.025H17.3l-.026-.008-3.3-1.031a.741.741,0,0,1-.52-.708V22.734a.741.741,0,0,1,.963-.708l3.08.962,3.08-.962a.741.741,0,0,1,.963.708v4.816a.741.741,0,0,1-.52.708l-3.3,1.031A.741.741,0,0,1,17.5,29.323Zm-2.559-2.318,1.818.568V24.311l-1.818-.568Zm3.3-2.695v3.263l1.818-.568V23.743Z"
                              transform="translate(0 -19.115)"
                              fill="#fbb415"
                            />
                          </svg>
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="about-unidetail shade1 mb-0">
                  <div className="row">
                    <div className="col-12">
                      <ul className="tableformt">
                        <ul className="tableformt">
                          <li
                            style={{
                              display:
                                university?.university_type != null
                                  ? ""
                                  : "none",
                            }}
                          >
                            Institution Type
                          </li>
                          <li
                            style={{
                              display:
                                university?.university_type != null
                                  ? ""
                                  : "none",
                            }}
                          >
                            {university?.university_type == 1
                              ? "Private"
                              : university?.university_type == 2
                              ? "Public"
                              : ""}
                          </li>
                          <li
                            style={{
                              display:
                                university?.intn_students != null ? "" : "none",
                            }}
                          >
                            International Students
                          </li>
                          <li
                            style={{
                              display:
                                university?.intn_students != null ? "" : "none",
                            }}
                          >
                            {university?.intn_students}
                          </li>
                          <li
                            style={{
                              display:
                                university?.application_deadline != null
                                  ? ""
                                  : "none",
                            }}
                          >
                            Application Deadline
                          </li>
                          <li
                            style={{
                              display:
                                university?.application_deadline != null
                                  ? ""
                                  : "none",
                            }}
                          >
                            {university?.application_deadline}
                          </li>
                        </ul>
                      </ul>
                      <ul className="tableformt">
                        {about_uni &&
                          about_uni?.map((data: any, i: any) => {
                            return (
                              <ul className="tableformt" key={i}>
                                <li
                                  style={{
                                    display: data?.title != "" ? "" : "none",
                                  }}
                                >
                                  {data?.title}
                                </li>
                                <li
                                  style={{
                                    display: data?.value != "" ? "" : "none",
                                  }}
                                >
                                  {data?.value}
                                </li>
                              </ul>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="acception-uni shade1">
                <div className="row">
                  <div className="col-12">
                    <div className="headingmains text-center pb-4">
                      <h2 className="titlewithline uniDetailsH2">
                        {" "}
                        {university?.university_name
                          ? university?.university_name + " "
                          : "Emirates Aviation University "}{" "}
                        Acceptance Rate
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.5"
                            height="31"
                            viewBox="0 0 27.5 31"
                          >
                            <g id="Accept" transform="translate(-40 -8)">
                              <path
                                id="Path_6334"
                                data-name="Path 6334"
                                d="M69,46H48V16H65l4,4Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fbb415"
                              />
                              <path
                                id="Path_6335"
                                data-name="Path 6335"
                                d="M320,16v4h4"
                                transform="translate(-262.5 -7.5)"
                                fill="#cca400"
                              />
                              <g
                                id="Group_4345"
                                data-name="Group 4345"
                                transform="translate(43 14.5)"
                              >
                                <path
                                  id="Path_6336"
                                  data-name="Path 6336"
                                  d="M88,112h16v2H88Z"
                                  transform="translate(-88 -112)"
                                  fill="#047475"
                                />
                                <path
                                  id="Path_6337"
                                  data-name="Path 6337"
                                  d="M88,176h16v2H88Z"
                                  transform="translate(-88 -172)"
                                  fill="#047475"
                                />
                                <path
                                  id="Path_6338"
                                  data-name="Path 6338"
                                  d="M88,240h16v2H88Z"
                                  transform="translate(-88 -232)"
                                  fill="#047475"
                                />
                                <ellipse
                                  id="Ellipse_154"
                                  data-name="Ellipse 154"
                                  cx="5"
                                  cy="5.5"
                                  rx="5"
                                  ry="5.5"
                                  transform="translate(13 11.5)"
                                  fill="#047475"
                                />
                              </g>
                              <path
                                id="Path_6339"
                                data-name="Path 6339"
                                d="M67,464H64.5a.5.5,0,0,0,0,1H67a.5.5,0,0,0,0-1Z"
                                transform="translate(-22.5 -427.5)"
                                fill="#047475"
                              />
                              <path
                                id="Path_6340"
                                data-name="Path 6340"
                                d="M129,464h-.5a.5.5,0,0,0,0,1h.5a.5.5,0,1,0,0-1Z"
                                transform="translate(-82.5 -427.5)"
                                fill="#047475"
                              />
                              <path
                                id="Path_6341"
                                data-name="Path 6341"
                                d="M80.5,107h16a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,80.5,107Zm.5-2H96v1H81Z"
                                transform="translate(-37.5 -90)"
                                fill="#fbb415"
                              />
                              <path
                                id="Path_6342"
                                data-name="Path 6342"
                                d="M80.5,171h16a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,80.5,171Zm.5-2H96v1H81Z"
                                transform="translate(-37.5 -150)"
                                fill="#fbb415"
                              />
                              <path
                                id="Path_6343"
                                data-name="Path 6343"
                                d="M80.5,235h16a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,80.5,235Zm.5-2H96v1H81Z"
                                transform="translate(-37.5 -210)"
                                fill="#fbb415"
                              />
                              <path
                                id="Path_6344"
                                data-name="Path 6344"
                                d="M90,296H80.5a.5.5,0,0,0,0,1H90a.5.5,0,1,0,0-1Z"
                                transform="translate(-37.5 -270)"
                                fill="#047475"
                              />
                              <path
                                id="Path_6345"
                                data-name="Path 6345"
                                d="M87,320H80.5a.5.5,0,0,0,0,1H87a.5.5,0,0,0,0-1Z"
                                transform="translate(-37.5 -287.5)"
                                fill="#047475"
                              />
                              <path
                                id="Path_6346"
                                data-name="Path 6346"
                                d="M81.5,345a.5.5,0,0,0,0-1h-1a.5.5,0,0,0,0,1Z"
                                transform="translate(-37.5 -315)"
                                fill="#047475"
                              />
                              <path
                                id="Path_6347"
                                data-name="Path 6347"
                                d="M121,345a.5.5,0,0,0,0-1h-.5a.5.5,0,0,0,0,1Z"
                                transform="translate(-75 -315)"
                                fill="#047475"
                              />
                              <path
                                id="Path_6348"
                                data-name="Path 6348"
                                d="M67.5,31.5A6.008,6.008,0,0,0,62,25.521V12.5a.5.5,0,0,0-.146-.354l-4-4A.5.5,0,0,0,57.5,8h-17a.5.5,0,0,0-.5.5v30a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5V37.479A6.008,6.008,0,0,0,67.5,31.5ZM58,9.707,60.293,12H58ZM41,38V9H57v3.5a.5.5,0,0,0,.5.5H61V25.521a6,6,0,0,0,0,11.958V38Zm20.5-1.5a5,5,0,1,1,5-5A5,5,0,0,1,61.5,36.5Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                              <path
                                id="Path_6349"
                                data-name="Path 6349"
                                d="M342.852,352.146a.5.5,0,0,0-.707,0l-3.224,3.224-2.165-1.3a.5.5,0,0,0-.514.858l2.5,1.5a.5.5,0,0,0,.611-.075l3.5-3.5A.5.5,0,0,0,342.852,352.146Z"
                                transform="translate(-277.499 -322.5)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="accept1">
                      <div className="shade1  secaccept">
                        <img src="/assets/img/kyc.png" alt="" />
                        <p>
                          <b>{university?.acceptance_rate}%</b> <br />
                          acceptance rate
                        </p>
                      </div>
                      <div className="shade1  secaccept">
                        <img src="/assets/img/application.png" alt="" />
                        <p>
                          {" "}
                          <b>{university?.total_applicants}</b> <br />
                          total applicants
                        </p>
                      </div>
                      <div className="shade1  secaccept">
                        <img src="/assets/img/reading-book.png" alt="" />
                        <p>
                          <b>{university?.total_enrolled}</b>
                          <br />
                          total enrolled
                        </p>
                      </div>
                    </div>
                    <div className="accept1 accept2 shade1">
                      <div className="secaccept">
                        <PieChart
                          paddingAngle={6}
                          data={[
                            {
                              title: "male",
                              value: parseFloat(male.toFixed(2))
                                ? parseFloat(male.toFixed(2))
                                : 1,
                              color: "#fbb415",
                            },
                            {
                              title: "female",
                              value: parseFloat(female.toFixed(2))
                                ? parseFloat(female.toFixed(2))
                                : 1,
                              color: "#047475",
                            },
                          ]}
                        />

                        {/* <img src="assets/img/analytics.png" alt="" /> */}
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="8"
                            viewBox="0 0 27 14"
                          >
                            <rect
                              id="Rectangle_2553"
                              data-name="Rectangle 2553"
                              width="27"
                              height="14"
                              transform="translate(27 14) rotate(180)"
                              fill="#fbb415"
                            />
                          </svg>
                          Male <br />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="8"
                            viewBox="0 0 27 14"
                          >
                            <rect
                              id="Rectangle_2554"
                              data-name="Rectangle 2554"
                              width="27"
                              height="14"
                              transform="translate(27 14) rotate(180)"
                              fill="#047475"
                            />
                          </svg>
                          Female
                        </p>
                      </div>
                      <div className="secaccept">
                        <img src="/assets/img/male-gender.png" alt="" />
                        <p>
                          {" "}
                          <b className="color-yellow">
                            {parseFloat(male.toFixed(2))
                              ? parseFloat(male.toFixed(2))
                              : 0}
                            %
                          </b>{" "}
                          <br />
                          male
                        </p>
                      </div>
                      <div className="secaccept">
                        <img src="/assets/img/femenine.png" alt="" />
                        <p>
                          <b className="color-green">
                            {parseFloat(female.toFixed(2))
                              ? parseFloat(female.toFixed(2))
                              : 0}
                            %
                          </b>
                          <br />
                          female
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-md-6"
                        style={{
                          display: undergraduate.length > 0 ? "" : "none",
                        }}
                      >
                        <div className="studywrp shade1 mb-2">
                          <div className="bgstudy">
                            <div className="row ">
                              <div className="col-12">
                                <h5 className="text-center">undergraduate</h5>
                              </div>
                            </div>
                          </div>
                          <div className="detailstudy">
                            <div className="row">
                              <div className="col-sm-12 text-left">
                                <table
                                  width="100%"
                                  style={{ border: "0" }}
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    {undergraduate &&
                                      undergraduate?.map(
                                        (data: any, i: any) => {
                                          return (
                                            <tr key={i}>
                                              <td
                                                style={{
                                                  borderBottom:
                                                    "2px solid #fcbd30",
                                                  padding: "10px",
                                                }}
                                                align="left"
                                              >
                                                {data?.title && data?.title}
                                                {/* undergraduate programs */}
                                              </td>
                                              <td
                                                style={{
                                                  borderBottom:
                                                    "2px solid #fcbd30",
                                                  padding: "10px",
                                                }}
                                                align="right"
                                              >
                                                {data?.value && data?.value}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6"
                        style={{
                          display: postgraduate.length > 0 ? "" : "none",
                        }}
                      >
                        <div className="studywrp shade1 mb-2">
                          <div className="bgstudy">
                            <div className="row ">
                              <div className="col-12">
                                <h5 className="text-center">postgraduate</h5>
                              </div>
                            </div>
                          </div>
                          <div className="detailstudy">
                            <div className="row">
                              <div className="col-sm-12 text-left">
                                <table
                                  width="100%"
                                  style={{ border: "0" }}
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    {postgraduate &&
                                      postgraduate?.map((data: any, i: any) => {
                                        return (
                                          <tr key={i}>
                                            <td
                                              style={{
                                                borderBottom:
                                                  "2px solid #fcbd30",
                                                padding: "10px",
                                              }}
                                              align="left"
                                            >
                                              {data?.title && data?.title}
                                              {/* undergraduate programs */}
                                            </td>
                                            <td
                                              style={{
                                                borderBottom:
                                                  "2px solid #fcbd30",
                                                padding: "10px",
                                              }}
                                              align="right"
                                            >
                                              {data?.value && data?.value}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="acception-uni shade1 testscore"
                style={{ display: resultArray?.length > 0 ? "" : "none" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="headingmains text-center pb-4">
                      <h4 className="titlewithline">
                        {" "}
                        Test Scores For International Applicants
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="41"
                            height="41.01"
                            viewBox="0 0 41 41.01"
                          >
                            <g
                              id="Icon_ionic-ios-timer"
                              data-name="Icon ionic-ios-timer"
                              transform="translate(-3.938 -3.938)"
                            >
                              <path
                                id="Path_6409"
                                data-name="Path 6409"
                                d="M24.443,44.948A20.505,20.505,0,0,1,10.212,9.679,1.646,1.646,0,0,1,12.5,12.047,17.209,17.209,0,1,0,26.083,7.311v6.418a1.651,1.651,0,1,1-3.3,0V5.588a1.65,1.65,0,0,1,1.651-1.651,20.505,20.505,0,0,1,.01,41.01Z"
                                transform="translate(0 0)"
                                fill="#fbb415"
                              />
                              <path
                                id="Path_6410"
                                data-name="Path 6410"
                                d="M12.879,11.444l10.2,7.331A3.087,3.087,0,1,1,19.492,23.8a2.981,2.981,0,0,1-.718-.718l-7.331-10.2a1.029,1.029,0,0,1,1.435-1.435Z"
                                transform="translate(3.351 3.351)"
                                fill="#fbb415"
                              />
                            </g>
                          </svg>
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row studywrp">
                  <div className="col-12">
                    <table
                      width="100%"
                      style={{ border: "0" }}
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ backgroundColor: "#eee", padding: "10px" }}
                            align="center"
                          >
                            <h6>exam</h6>
                          </td>
                          <td
                            style={{ backgroundColor: "#eee", padding: "10px" }}
                            align="center"
                          >
                            <h6>avg. score</h6>
                          </td>
                          <td
                            style={{ backgroundColor: "#eee", padding: "10px" }}
                            align="center"
                          >
                            <h6>min. score</h6>
                          </td>
                          <td
                            style={{ backgroundColor: "#eee", padding: "10px" }}
                            align="center"
                          >
                            <h6>max. score</h6>
                          </td>
                          <td
                            style={{ backgroundColor: "#eee", padding: "10px" }}
                            align="center"
                          >
                            <h6>submitted</h6>
                          </td>
                        </tr>
                      </tbody>
                      {resultArray &&
                        Object.keys(resultArray).map((keyName, i) => {
                          return (
                            <tbody key={i}>
                              <tr key={i}>
                                <td
                                  className="bgstudy"
                                  align="center"
                                  colSpan={5}
                                >
                                  <h5 className="text-center">{keyName}</h5>
                                </td>
                              </tr>
                              {keyName &&
                                resultArray[keyName]?.map(
                                  (data: any, index: any) => {
                                    return (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="center"
                                        >
                                          <b className="yellow">
                                            {data?.exam_name}
                                          </b>
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="center"
                                        >
                                          {data?.avg_mark}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="center"
                                        >
                                          {data?.min_mark}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="center"
                                        >
                                          {data?.max_mark}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="center"
                                        >
                                          {data?.submit}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </div>

              <div
                className="acception-uni shade1 mb-0"
                style={{ display: tuitionFee.length > 0 ? "" : "none" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="headingmains text-center pb-4">
                      <h4 className="titlewithline">
                        {" "}
                        {university?.university_name
                          ? university?.university_name + " "
                          : "Emirates Aviation University "}{" "}
                        Fee Structure{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33.333"
                            height="33.667"
                            viewBox="0 0 33.333 33.667"
                          >
                            <path
                              id="Icon_material-local-offer"
                              data-name="Icon material-local-offer"
                              d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                              transform="translate(-3 -3)"
                              fill="#fbb415"
                            ></path>
                          </svg>
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="studywrp shade1 mb-2">
                      <div className="bgstudy">
                        <div className="row ">
                          <div className="col-12">
                            <h5 className="text-center">tuition fees (avg)</h5>
                          </div>
                        </div>
                      </div>
                      <div className="detailstudy">
                        <div className="row">
                          <div className="col-sm-12 text-left">
                            <table
                              width="100%"
                              style={{ border: "0" }}
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <tbody>
                                {typeof tuitionFee == "object" &&
                                  tuitionFee?.map((data: any, i: any) => {
                                    return (
                                      <tr key={i}>
                                        <td
                                          style={{
                                            borderBottom: "2px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="left"
                                        >
                                          {data?.tuition_fee_title}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "2px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="right"
                                        >
                                          {data?.tuition_fee_value}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="studywrp shade1 mb-2">
                      <div className="bgstudy">
                        <div className="row ">
                          <div className="col-12">
                            <h5 className="text-center">on campus</h5>
                          </div>
                        </div>
                      </div>
                      <div className="detailstudy">
                        <div className="row">
                          <div className="col-sm-12 text-left">
                            <table
                              width="100%"
                              style={{ border: "0" }}
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <tbody>
                                {typeof campusFee == "object" &&
                                  campusFee?.map((data: any, i: any) => {
                                    return (
                                      <tr key={i}>
                                        <td
                                          style={{
                                            borderBottom: "2px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="left"
                                        >
                                          {data?.campus_fee_title}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "2px solid #fcbd30",
                                            padding: "10px",
                                          }}
                                          align="right"
                                        >
                                          {data?.campus_fee_value}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="acception-uni shade1">
                <div className="row">
                  <div className="col-12">
                    <div className="headingmains text-center pb-4">
                      <h2 className="titlewithline uniDetailsH2">
                        {" "}
                        {university?.university_name} location
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="29.59"
                            height="33.667"
                            viewBox="0 0 29.59 33.667"
                          >
                            <g id="location" transform="translate(0)">
                              <g
                                id="Group_4259"
                                data-name="Group 4259"
                                transform="translate(3.946)"
                              >
                                <g
                                  id="Group_4258"
                                  data-name="Group 4258"
                                  transform="translate(0)"
                                >
                                  <path
                                    id="Path_6250"
                                    data-name="Path 6250"
                                    d="M101.85,0A10.845,10.845,0,0,0,93.03,17.162l7.989,12.466a.986.986,0,0,0,1.661,0L110.7,17.12A10.849,10.849,0,0,0,101.85,0Zm0,15.781a4.932,4.932,0,1,1,4.932-4.932A4.937,4.937,0,0,1,101.85,15.781Z"
                                    transform="translate(-91)"
                                    fill="#fbb415"
                                  />
                                </g>
                              </g>
                              <g
                                id="Group_4261"
                                data-name="Group 4261"
                                transform="translate(0 22.665)"
                              >
                                <g id="Group_4260" data-name="Group 4260">
                                  <path
                                    id="Path_6251"
                                    data-name="Path 6251"
                                    d="M53.506,344.7l-4.967,7.765a3.261,3.261,0,0,1-5.489,0L38.075,344.7C33.7,345.709,31,347.563,31,349.778c0,3.844,7.623,5.918,14.795,5.918s14.795-2.074,14.795-5.918C60.59,347.561,57.888,345.706,53.506,344.7Z"
                                    transform="translate(-31 -344.695)"
                                    fill="#047475"
                                  />
                                </g>
                              </g>
                            </g>
                            <path
                              id="Path_6252"
                              data-name="Path 6252"
                              d="M3.151,0A3.151,3.151,0,1,1,0,3.151,3.151,3.151,0,0,1,3.151,0Z"
                              transform="translate(11.794 7.639)"
                              fill="#047475"
                            />
                          </svg>
                        </span>
                      </h2>
                      <p>
                        {university?.UniversityDetail != null
                          ? university?.UniversityDetail?.address
                          : ""}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    display:
                      university.video_url != null ? "inline-block" : "none",
                  }}
                >
                  <div className="col-12 location-uni __location">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <iframe
                          src={abcsd?.props?.src}
                          width="600"
                          height="500"
                          style={{ border: "0" }}
                          allowFullScreen
                          loading="lazy"
                        ></iframe> */}
                      </div>
                      <div className="col-md-12 ">
                        {university.video_url != null ? (
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#video-pop"
                          >
                            <div className="__overlay">
                              <i className="fa fa-youtube-play _youtube"></i>
                            </div>
                            <img
                              width="100%"
                              src={
                                "https://img.youtube.com/vi/" +
                                res[1] +
                                "/0.jpg"
                              }
                              alt=""
                            />
                            {/* <iframe 
                              width="560"
                              height="315"
                              src={embeddedUrl}
                              frameBorder="0"
                              allowFullScreen
                            ></iframe> */}
                          </a>
                        ) : (
                          <img
                            src="/assets/img/no-video.jpg"
                            height="100%"
                            width="100%"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="acception-uni shade1 pb-0">
                <h2 className="yellow mb-4 similarUniH2">
                  Similar to {university?.university_name}{" "}
                </h2>
                <div className="row">
                  {universities &&
                    universities.map((data: any, i: any) => {
                      return (
                        i < 6 && (
                          <div className="col-md-6 col-lg-4 mb-4 college-card text-decoration-none">
                            <div className="wrap">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  otherUni(data?.slug);
                                }}
                              >
                                <div className="top-section">
                                  {data.UniversityDetail.banners !== null ? (
                                    <img
                                      src={
                                        baseurl +
                                        JSON.parse(
                                          data.UniversityDetail.banners
                                        )
                                      }
                                      className="jsx-2737925295 college-img "
                                    />
                                  ) : (
                                    <img
                                      src="/assets/img/noImage.jpeg"
                                      className="jsx-2737925295 college-img "
                                      height="160px"
                                      width="100%"
                                    />
                                  )}

                                  <div className="overlay"></div>
                                  <div className="rating-section">
                                    <button
                                      className={"wishlist"}
                                      onClick={(e: any) => handleUniFollow(e)}
                                      type="button"
                                    >
                                      <i className="fa fa-heart"></i>
                                    </button>
                                    {/* <button type="button" className="btn follow-btn" onClick={(e:any)=> handleUniFollow(e)}>
                                    <span className="pointer-none icon mr-1 icon-white">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12.822"
                                        height="11.18"
                                        viewBox="0 0 12.822 11.18"
                                      >
                                        <g
                                          id="Icon_feather-user-plus"
                                          data-name="Icon feather-user-plus"
                                          transform="translate(0.5 0.5)"
                                        >
                                          <path
                                            id="Path_6294"
                                            data-name="Path 6294"
                                            d="M9.561,25.893V24.762A2.208,2.208,0,0,0,7.411,22.5H3.649A2.208,2.208,0,0,0,1.5,24.762v1.131"
                                            transform="translate(-1.5 -15.714)"
                                            fill="none"
                                            stroke="#fbb415"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_6295"
                                            data-name="Path 6295"
                                            d="M11.049,6.762A2.208,2.208,0,0,1,8.9,9.025,2.208,2.208,0,0,1,6.75,6.762,2.208,2.208,0,0,1,8.9,4.5,2.208,2.208,0,0,1,11.049,6.762Z"
                                            transform="translate(-4.869 -4.5)"
                                            fill="none"
                                            stroke="#fbb415"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_6296"
                                            data-name="Path 6296"
                                            d="M30,12v3.393"
                                            transform="translate(-19.79 -9.173)"
                                            fill="none"
                                            stroke="#fbb415"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_6297"
                                            data-name="Path 6297"
                                            d="M28.724,16.5H25.5"
                                            transform="translate(-16.902 -11.976)"
                                            fill="none"
                                            stroke="#fbb415"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                    </span>{" "}
                                    Follow
                                  </button> */}
                                  </div>
                                </div>
                                <div className="mid-section pl-2 pr-2">
                                  <div className="college-info">
                                    <h6 className="text-white d-block mt-2 mb-0 text-md college-name">
                                      {data.university_name}
                                    </h6>

                                    <div className="other-info pb-2">
                                      <div className="location text-sm d-inline-block text-white">
                                        {/* <span className="icon">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  ></i>
                                </span>{" "}
                                {data.UniversityDetail.address}  */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bottom-section bg-white">
                                <div className="info d-block p-2">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <span className="course-name">
                                        Ranking{" "}
                                        {data.ranking != null
                                          ? data.ranking
                                          : 0}{" "}
                                      </span>
                                      <span className="course-name">
                                        USD {data.application_fee}{" "}
                                      </span>
                                      <span className="course-name">
                                        {/* view all courses & fees */}
                                      </span>
                                    </div>
                                    <a
                                      href={
                                        data?.UniversityDetail?.brochure ==
                                          "" ||
                                        data?.UniversityDetail?.brochure == null
                                          ? "javascript:void(0)"
                                          : data?.UniversityDetail?.brochure &&
                                            baseurl +
                                              data?.UniversityDetail?.brochure
                                      }
                                      type="button"
                                      download
                                      className={
                                        data?.UniversityDetail?.brochure ==
                                          "" ||
                                        data?.UniversityDetail?.brochure == null
                                          ? "awe-btnbook download brochuredis downloadwithicon smdbtn"
                                          : "awe-btnbook download downloadwithicon smdbtn"
                                      }
                                    >
                                      Download Brochure{" "}
                                      <i className="iconx">
                                        <img src="/assets/img/icons/download.svg" />
                                      </i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
              {seo?.footer_content && (
                <div className="about-uni-text shade1">
                  <SeoFooterContent />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    Get
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code: <span>{modelData[0]?.coupon_code}</span>{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="video-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog video-model" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="feat-text">
                  <iframe
                    width="560"
                    height="315"
                    src={embeddedUrl}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      Apply eligibility popup
      */}
      <div
        id="date_foo"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="row app_now_">
                <div className="col-lg-12">
                  <p className="app_now_head">
                    Are you sure want to apply for this course?
                  </p>
                  <p className="app_max_err">*You can choose any 3 courses.</p>
                  <p className="">
                    You have selected{" "}
                    {SelectedCourseCount ? SelectedCourseCount : 0} out of 3
                    courses.
                  </p>
                </div>
              </div>
              <figcaption className="details-box pb-3 pt-0">
                <div className="bttns text-center">
                  {(() => {
                    if (SelectedCourseCount > 0) {
                      return (
                        <a className="applybtn bggreadent">
                          <span
                            className="btn btn-success font-weight-bold _submit_btn"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e: any) => handleApplyNow(e)}
                          >
                            apply now
                          </span>
                        </a>
                      );
                    }
                  })()}
                  {(() => {
                    if (SelectedCourseCount < 3) {
                      return (
                        <span
                          className="font-weight-bold _app_add_m"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          + add more
                        </span>
                      );
                    } else {
                      return (
                        <p className="app_max_ pt-2">
                          *you have already reached max selection of courses
                        </p>
                      );
                    }
                  })()}
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UniversitiesDetails;
