import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Contactus from "./Contactus";
import CounsellorList from "../CounsellorList/CounsellorList";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
  officeAction,
} from "../../redux/common/action";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";

var other_details: any = [];
function Coach() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const [available, setavailable] = useState<any[]>([]);
  const [availableid, setavailableid] = useState<any[]>([]);
  const [fees, setfees] = useState<any[]>([]);
  const [feesid, setfeesid] = useState<any[]>([]);
  const [experience, setexperience] = useState<any[]>([]);
  const [experienceid, setexperienceid] = useState<any[]>([]);
  const [country, setcountry] = useState<any[]>([]);
  const [countryid, setcountryid] = useState<any[]>([]);
  const [availableOpne, setavailableOpne] = useState<any>(false);
  const [feesOpne, setfeesOpne] = useState<any>(false);
  const [experienceOpne, setexperienceOpne] = useState<any>(false);
  const [countryOpne, setcountryOpne] = useState<any>(false);
  const dispatch = useDispatch();
  const [CoachCounsellor, setCoachCounsellor] = useState<any>();
  const [programInterest, setProgramInterest] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [AreaQuery, setAreaQuery] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [interestedIn, setInterestedIn] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [Training, setTraining] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [About, setAbout] = useState<any>({
    name: "",
    label: "",
    value: "",
  });

  /***
   * Submit Query
   */

  const handleCoach = (e: any) => {
    const { name, value } = e.target;
    setCoachCounsellor({
      ...CoachCounsellor,
      [name]: value,
      other_details: other_details,
      service_name: "counsellor",
    });
  };

  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };

  const hendleavailableOpne = (e: any) => {
    if (availableOpne == true) {
      setavailableOpne(false);
    } else {
      setavailableOpne(true);
    }
  };
  const hendlefeesOpne = (e: any) => {
    if (feesOpne == true) {
      setfeesOpne(false);
    } else {
      setfeesOpne(true);
    }
  };
  const hendleexperienceOpne = (e: any) => {
    if (experienceOpne == true) {
      setexperienceOpne(false);
    } else {
      setexperienceOpne(true);
    }
  };
  useEffect(() => {
    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  }, []);
  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const submitQuery = (e: any) => {
    const Name = $("#Name").val();
    const Mobile = $("#Mobile").val();
    const Email = $("#Email").val();
    const City = $("#City").val();

    const IsProgram = $("select[name=program]").val();
    const InArea = $("select[name=areas]").val();
    const IsInterest = $("select[name=interest]").val();
    const IsTraining = $("textarea[name=training]").val();
    const IsAbout = $("textarea[name=about]").val();

    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!Name) {
      $("#Name").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Mobile) {
      $("#Mobile").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Mobile) {
      if (Mobile.length != 10) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (Mobile) {
      if (phoneno.test(Mobile) === false) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (!Email) {
      $("#Email").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Email) {
      if (regex.test(Email) === false) {
        $("#Email").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (!City) {
      $("#City").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }

    var InAbout = {
      name: "about",
      label: "what are you most curious about?",
      value: IsAbout ? IsAbout : "N/A",
    };
    var InTraining = {
      name: "training",
      label: "how did you hear about mentor training EDU?",
      value: IsTraining ? IsTraining : "N/A",
    };
    var InInterest = {
      name: "interest",
      label: "i am interested in",
      value: IsInterest ? IsInterest : "N/A",
    };
    var InAreas = {
      name: "area",
      label: "in what areas do you have questions?",
      value: InArea ? InArea : "N/A",
    };
    var InProgram = {
      name: "program",
      label: "in which program(s) are you interested?",
      value: IsProgram ? IsProgram : "N/A",
    };

    other_details.push(InProgram, InAreas, InInterest, InTraining, InAbout);

    $("#consulorIn").prop("disabled", true);
    dispatch(officeAction.submitQuery(CoachCounsellor, history));
    // toast.success('Thanks for showing your interest, we will get back to you soon.');
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  //   })

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div className="container">
        <section className=" tag-term">
          <Breadcrumb page={"Counsellor"} data={{ slug: "", path: "" }} />
          <div className="text-right pb-4 mr-3">
            <SocialShareButton urlsforShare={urlsforShare} data={""} />
          </div>
          <div className="headingmains text-center">
            <h2
              className="titlewithline aos-init aos-animate"
              data-aos="flip-left"
            >
              counsellor
              <span>
                <img
                  className="service-funding"
                  src="assets/img/counsellor.png"
                  alt=""
                />
              </span>
            </h2>
          </div>
        </section>
        <div className="counsellorBox1">
          <div className="counsellor-section">
            <section className="">
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-1"></div> */}
                  <div className="col-lg-12">
                    {seo?.header_content && (
                      <div className="about-uni-text shade1">
                        <SeoHeaderContent />
                      </div>
                    )}
                    <CounsellorList
                      available={available}
                      fees={fees}
                      experience={experience}
                      country={country}
                    />
                  </div>
                </div>
              </div>
            </section>

            <br />
          </div>
          <div className="banner-section">
            <VerticalAddSlider />
          </div>
        </div>
        <section className="terms">
          <div className="container">
            <div className="row">
              <div className="terms-s">
                <p>
                  You've labored tough to urge during which you're now. You
                  understand you've got the potential to be among the first-rate
                  for your field. You would like to form the right selections
                  approximately your subsequent steps now if you would like to
                  get your goals. But there are such tons of alternatives to be
                  had it's tough to know what's first-rate for you. With a
                  considerable sort of enjoying and expert careers steerage
                  qualifications, we permit you to pick the right professional
                  direction with inside the twenty-first century. For school
                  students who're uncertain of which profession first-rate fits
                  their capabilities and aptitudes, we'll assist in slender your
                  alternatives. For those who've selected their direction, we'll
                  advocate at the right publications and maximum suitable
                  profile-constructing sports to beautify your possibilities of
                  success. Studying overseas could also be high-priced and
                  involve a large investment. Applying to the 'proper'
                  institutes can maximize your possibilities of getting
                  want-primarily based economic resources or merit-primarily
                  based totally scholarships. Our counselors assist you in
                  filling the specified bureaucracy and checking your documents,
                  advising you with the first-rate techniques to urge the
                  simplest feasible economic resource and scholarships from
                  institutes overseas.
                </p>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new"
                        src="assets/img/coun11.jpg"
                        alt=""
                        id=""
                      />
                      <div className="postcard__text postcard__text1 px-0 pl-4">
                        <h4 className="pb-3 card-head1">
                          What is Educational Counselling?
                        </h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Educational counseling suggests that schooling
                          performs a key role. The counselors will assist you to
                          obtain instructional achievement. You'll inform
                          everyone approximately schooling and therefore the way
                          schooling is significant. Educational counselors
                          additionally assist children to develop and turn into
                          assured of their research. The utmost critical issue
                          for the scholars is schooling counseling. Proper
                          mentoring permits students to satisfy their
                          objectives.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard postcard-n1">
                      <img
                        className="postcard__img-new postcard__img-new1"
                        src="assets/img/c-r23.jpg"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1 px-0 pr-5">
                        <h4 className="pb-3 card-head1">
                          Who are Educational Counsellors?
                        </h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          <p className=" px-0">
                            Thinking approximately a counselor, we're
                            considering a photograph of a pleasant, well-manned,
                            and pushed individual. Career courses have grown to
                            be so vital that faculties and establishments have
                            attached unique counseling cells for school
                            students. Furthermore, a counselor has numerous
                            capabilities to hold out within side the
                            cutting-edge schooling system. Additionally, to
                            allow you to know the best things, those
                            professionals can assist you to create a choice on
                            the utmost vital intersection of your life. In each
                            stage of your instructional and expert direction,
                            schooling counselor features a sizable
                            characteristic because it enables you to make
                            knowledgeable judgments. Many of us sense that in
                            our education or activity pursuit we don't want
                            expert supervision, but the proper mentor may
                            additionally certainly enhance your life. Types of
                            Educational Counselling.
                          </p>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <h4 className="pb-3 card-head1">
                            There are 3 center sorts of Educational Counselling:
                          </h4>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            1. Educational Guidance and Counselling
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            The observe overseas steerage and counseling
                            service, whether or not or not it's choice of the
                            proper move to seek out out the first-rate
                            university and direction after graduating excessive
                            school, will offer college students the important
                            steerage keep with instructional demands.
                            Educational counselors are typically positioned at
                            faculties and universities and you'll additionally
                            be trying to find assistance from schooling advisory
                            offerings to reap the simplest mentoring to your
                            instructional profession.
                          </p>
                        </div>

                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            2. Vocational Counselling
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            Vocational counseling is simply like professional
                            counseling, however focuses extra on deciding the
                            proper employment or employment possibility
                            withinside the place or enterprise during which they
                            need to figure out.
                          </p>
                        </div>

                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            3. Career Counselling
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            Career consulting may be a whole mixture of
                            educational and vocational counseling because it
                            focuses each on college students and operating
                            experts and offers them the simplest mentoring at
                            every degree of the direction!
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new"
                        src="assets/img/coaching_05.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1 px-0 pl-5">
                        <h4 className="pb-3 card-head1">
                          What does Admissify Offer?
                        </h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Admissify sticks through its call and ensures that the
                          moral responsibility lies above the organizations on
                          the topic of presenting excellent offerings to school
                          students and expert workers. Many students are
                          counseled through pioneers of their respective
                          professions. They gather schooling. At Admissify, we
                          suppose its miles best whilst a pupil obtains most
                          education regardless of limits and budgetary
                          regulations that the goal of consulting is achieved.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard postcard-n1">
                      <img
                        className="postcard__img-new"
                        src="assets/img/concul.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1 px-0 pr-5">
                        <h4 className="pb-3 card-head1">conclusion</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          We recognize how hard it might be to pursue your
                          research overseas. We understand that it’s about your
                          dream that is about to be real. We value your each and
                          every doubt equally. We provide a variety of Student
                          Essentials offerings to eliminate complexity and
                          strain making your worldwide observe enjoy the
                          journey, a hassle-loose adventure. So that you only
                          focus on your studies. Need to assist? Explore our
                          offerings and an Admissify counselor could also be
                          assigned to manual via each step of your manner to
                          remain and observe overseas.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            {seo?.footer_content && (
              <div className="about-uni-text shade1 mt-5">
                <SeoFooterContent />
              </div>
            )}
          </div>
        </section>
        {/*   
     
     <Contactus /> */}

        <section className="categories section bg-grey" id="c-form">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12">
                <div
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "0px",
                    height: "0px",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 24 24" id="expand-more">
                      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                    <symbol viewBox="0 0 24 24" id="close">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                  </svg>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10">
                        <h5 className="apply-n">apply now</h5>
                        <p className="b-10 coach-view">
                          We'd love to hear from you! Please fill out the form
                          below so we may best support you
                        </p>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="fields-wrp">
                          <form id="counsellor">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="name"
                                    value={CoachCounsellor?.name}
                                    id="Name"
                                    onChange={(e) => {
                                      handleCoach(e);
                                    }}
                                  />
                                  <label>name</label>
                                  <span className="fa fa-user p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="mobile"
                                    maxLength="10"
                                    id="Mobile"
                                    value={CoachCounsellor?.mobile}
                                    onChange={(e) => {
                                      handleCoach(e);
                                    }}
                                  />
                                  <label>mobile</label>
                                  <span className="fa fa-phone p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="email"
                                    id="Email"
                                    value={CoachCounsellor?.email}
                                    onChange={(e) => {
                                      handleCoach(e);
                                    }}
                                  />
                                  <label>email</label>
                                  <span className="fa fa-envelope p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="city"
                                    id="City"
                                    maxLength="10"
                                    value={CoachCounsellor?.city}
                                    onChange={(e) => {
                                      handleCoach(e);
                                    }}
                                  />
                                  <label>city</label>
                                  <span className="fa fa-map-marker p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <span className="fa fa-book p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="program"
                                    value={programInterest?.program}
                                  >
                                    <option value="">
                                      in which program(s) are you interested?
                                    </option>
                                    <option value="Academic Life Coaching">
                                      Academic Life Coaching
                                    </option>
                                    <option value="University Academic Life Coaching">
                                      University Academic Life Coaching
                                    </option>
                                    <option value="Executive Coaching">
                                      Executive Coaching
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <span className="fa fa-question p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="area"
                                    value={AreaQuery?.area}
                                  >
                                    <option value="">
                                      in what areas do you have questions?
                                    </option>
                                    <option value="Course Schedule">
                                      Course Schedule
                                    </option>
                                    <option value="Program Specializations">
                                      Program Specializations
                                    </option>
                                    <option value="Program Logistics (length, frequency, topics, etc.)">
                                      Program Logistics (length, frequency,
                                      topics, etc.)
                                    </option>
                                    <option value="Other (please share below)">
                                      Other (please share below)
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                              <span className="fa fa-hand-o-up p-2"></span>
                              <select
                                className="form-control c-select"
                                name="interest"
                                value={interestedIn?.interest}
                              >
                                <option value="">i am interested in..</option>
                                <option value="receiving an information packet">
                                  receiving an information packet
                                </option>
                                <option value="scheduling a 20-minute call with an Admission Advisor">
                                  scheduling a 20-minute call with an Admission
                                  Advisor
                                </option>
                              </select>
                            </div>
                            <div className="field">
                              <textarea
                                autoComplete="new-off"
                                className="q-msg border__"
                                rows="4"
                                cols="50"
                                placeholder="how did you hear about mentor training EDU?"
                                name="training"
                                value={Training?.training}
                              ></textarea>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="field">
                              <textarea
                                autoComplete="new-off"
                                className="q-msg border__"
                                rows="4"
                                cols="50"
                                placeholder="what are you most curious about?"
                                name="about"
                                value={About?.about}
                              ></textarea>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="fieldbtn text-center">
                                  <input
                                    onClick={(e) => {
                                      submitQuery("counsellor");
                                    }}
                                    type="button"
                                    data-toggle="modal"
                                    className="bggreadent apply-sub-btn"
                                    value="submit"
                                    id="consulorIn"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Coach;
