import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { UniversityAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url";
import Modal from "react-responsive-modal";
import { useHistory } from "react-router-dom";

function AdSlider() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const dispatch = useDispatch();
  const adBanner: any[] = useSelector((state: any) =>
    state.unversity.adBanner ? state.unversity.adBanner : []
  );
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [link, setLink] = useState("");

  // console.log(adBanner, "banners");

  const handleClick = (data: any) => {
    console.log(data.add_type);

    if (data.add_type === 1) {
      setUrl(data?.images);
      setLink(data.ad_hyperlink);
      setOpen(true);
    } else if (data.ad_hyperlink) {
      const url =
        data.ad_hyperlink.startsWith("http://") ||
        data.ad_hyperlink.startsWith("https://")
          ? data.ad_hyperlink
          : `https://${data.ad_hyperlink}`;

      // Open the external link in a new tab
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const handleModalClick = () => {
    const url =
      link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `https://${link}`;

    // Open the external link in a new tab
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div style={{ width: "100%", alignItems: "center" }}>
      <div className="ad-slider mb-12  ">
        <Slider {...settings}>
          {/* <div className="itemslider">
            <a href="https://forms.gle/r8c3Q93Y7nwpdixi6" target="_blank"><img src="/assets/img/banner-live-01.jpg" alt="" /> </a>
          </div> */}
          {adBanner?.length > 0 &&
            adBanner
              .filter((item) => item.add_type !== 3)
              .map((data: any) => (
                <div
                  className="itemslider"
                  style={{ height: "auto", maxHeight: "200px" }}
                >
                  <img
                    style={{
                      borderRadius: "10px",
                      height: "auto",
                      maxHeight: "200px",
                      cursor: "pointer",
                    }}
                    src={URL.API_BASE_URL + data?.images}
                    alt=""
                    onClick={() => handleClick(data)}
                  />
                </div>
              ))}
          {/* <div className="itemslider">
            <img src="/assets/img/Scholarship-ad-Banner-for-new-website.jpg" alt="" />
          </div>
          <div className="itemslider">
            <img src="/assets/img/IELTS-AD-BANNER-1034-FOR-NEW-WEBSITE.jpg" alt="" />
          </div> */}
        </Slider>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
      >
        <img
          style={{ maxWidth: "100%", cursor: "pointer" }}
          src={URL.API_BASE_URL + url}
          onClick={handleModalClick}
        />
      </Modal>
    </div>
  );
}

export default AdSlider;
