import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  UniversityCourseAction,
  counselloerAction,
  streamAction,
  UniversityAction,
} from "../../redux/common/action";
import MenuOutSideClick from "../../layout/header/outsideClick";
import { useHistory } from "react-router-dom";
import AdSlider from "../AdSlider/AdSlider";
import CourseFilter from "../coursefilter/CourseFilter";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import StarRatings from "react-star-ratings";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { URL } from "../../redux/common/url";
import Seo from "../../Component/Seo/Seo";
import { sorting } from "../../Utils/Common/Common";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";

function Program() {
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);
  const dispatch = useDispatch();
  const history = useHistory();
  const [course, setcourse] = useState<any[]>([]);
  const [search, setsearch] = useState<any[]>([]);
  const [courseid, setcourseid] = useState<any[]>([]);
  const [studylevel, setstudylevel] = useState<any>("1");
  const [sortBy, setosrtBy] = useState<any>();
  const baseurl = URL.API_BASE_URL;
  const id: any = useLocation();
  const [loadmore, setloadmore] = useState<any>(50);
  const [offset, setoffset] = useState<any>(0);
  const [readMore, setreadMore] = useState<any>(5);
  const [searchRe, setsearchRe] = useState<any>("");
  const [data, setData] = useState([]);
  const [countryOpne, setcountryOpne] = useState<any>(isMobile ? true : false);
  const [indexMore, setIndexMore] = useState<any>("");

  const university: any = useSelector((state: any) =>
    state.unversity.universityById ? state.unversity.universityById : []
  );
  // console.log('isMobile',isMobile);
  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };
  const universitycourse: any = useSelector((state: any) =>
    state.universitycourse.universitycourse
      ? state.universitycourse.universitycourse
      : []
  );
  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const allCourse: any = useSelector((state: any) =>
    state.universitycourse.allCourse ? state.universitycourse.allCourse : []
  );

  const activeStream: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );
  const StreamName = activeStream.map((s) => s.stream_name);
  const filteredStream = activeStream.filter(
    ({ stream_name }, index) => !StreamName.includes(stream_name, index + 1)
  );

  const activecourse_ =
    allCourse &&
    allCourse?.filter((data: any) => {
      return data.level == studylevel;
    });

  const activecourse = activecourse_.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  const [title, settitle] = useState<any>("course-list");
  useEffect(() => {
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: "",
        Offset: 0,
        Limit: 50,
      })
    );
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);
  // useEffect(() => {
  //   dispatch(UniversityCourseAction.getAllCourses({}));
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(counselloerAction.getAllCounsellor({}));
    return () => {};
  }, []);

  // useEffect(() => {
  //   dispatch(streamAction.getstreamlist({}));
  //   return () => {};
  // }, []);

  // filter  ====

  const uniCourse = universitycourse.filter((data: any) => {
    return data.university_id;
  });

  const filCourse = uniCourse.filter((data: any) => {
    return data.course_level == studylevel;
  });

  const universityCourse = filCourse.sort(function (a: any, b: any) {
    if (sortBy === "asc") {
      return (
        a.first_yr_fee.replace(/,/g, "") - b.first_yr_fee.replace(/,/g, "")
      );
    }
    if (sortBy === "desc") {
      return (
        b.first_yr_fee.replace(/,/g, "") - a.first_yr_fee.replace(/,/g, "")
      );
    }
  });

  const sortByName = (e: any) => {
    setosrtBy(e);
  };
  const levelofstudy = (e: any) => {
    setstudylevel(e);
  };
  const hendleLodemore = (e: any) => {
    let arrayid = [...courseid];
    setloadmore(loadmore);
    setoffset(offset + 50);

    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: searchRe ? searchRe : "",
        Limit: loadmore,
        Offset: offset + 50,
        CourseId: arrayid.length > 0 ? arrayid : "",
      })
    );

    return () => {};
  };
  const uniAndCouresHendle = (e: any) => {
    setsearchRe(e);
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: e,
        Offset: 0,
        Limit: 50,
        /*  limit:10 */
      })
    );
    return () => {};
  };

  const hendlSearchCourseByStream = (data: any) => {
    setcourse([]);
    setcourseid([]);
    // var Ids = data.split('&');
    setsearch(data);
    setsearchRe(data);
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: data ? data : "",
        Limit: 50,
        Offset: 0,
      })
    );
  };
  const hendlClickCourse = (event: any) => {
    setsearchRe("");
    let array = [...course];
    let arrayid = [...courseid];
    if (event.target.checked) {
      arrayid.push(event.target.value);
      array.push(event.target.name);
      setcourseid(arrayid);
      setcourse(array);
      dispatch(
        UniversityCourseAction.getAllUniCourses({
          CourseId: arrayid,
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
        })
      );
    } else {
      let coun = array.indexOf(event.target.name);

      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setcourseid(arrayid);
        setcourse(array);
        dispatch(
          UniversityCourseAction.getAllUniCourses({
            CourseId: arrayid.length > 0 ? arrayid : "",
            SearchTerm: "",
            Offset: 0,
            Limit: 50,
          })
        );
      }
    }
  };
  const removeCourse = (event: any, value: any) => {
    setsearchRe("");
    let array = [...course];
    let arrayid = [...courseid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setcourse(array);
      setcourseid(arrayid);
      dispatch(
        UniversityCourseAction.getAllUniCourses({
          CourseId: arrayid.length > 0 ? arrayid : "",
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
        })
      );
    }
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  useEffect(() => {
    window?.scrollTo(0, 0);
  });

  return (
    <div>
      <Seo slug={window.location.pathname} />
      <ScrollToTop />
      <section className="unifilter-page unifilter__mob" id="program">
        <div className="container">
          <Breadcrumb page={"Course"} data={{ slug: "", path: "" }} />
          <div className="program-search shade1">
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    ref={anchorRef1}
                    type="text"
                    className="form-control"
                    placeholder="search stream and course"
                    value={searchRe}
                    onChange={(e) => {
                      uniAndCouresHendle(e.target.value);
                    }}
                  />
                  {anchor1 && (
                    <div
                      className="searchuniversitiesandcourses"
                      style={{ padding: "0px", width: "20%" }}
                    >
                      <ul>
                        {filteredStream &&
                          filteredStream?.map((data: any, i: any) => {
                            return (
                              i < 15 && (
                                <li
                                  onClick={() => {
                                    hendlSearchCourseByStream(
                                      data?.stream_name
                                    );
                                    setsearchRe("");
                                  }}
                                  key={i}
                                >
                                  {data?.stream_name}
                                </li>
                              )
                            );
                          })}
                      </ul>
                    </div>
                  )}
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="serchsuggest">
                  <p>search by</p>
                  <ul>
                    {filteredStream &&
                      filteredStream?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <a
                              onClick={(e: any) =>
                                hendlSearchCourseByStream(data?.stream_name)
                              }
                            >
                              {data?.stream_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-2 col-md-pull-10"
              // style={{ border: "1px solid red" }}
            >
              <div className="page-sidebar">
                <div className="widget sidebar-title">
                  <h5>
                    <span>
                      selected <b>filters</b> <i className="fa fa-angle-up"></i>
                    </span>{" "}
                  </h5>
                  <ul
                    className={
                      course?.length !== 0
                        ? "clear-filter"
                        : "clear-filter filDisNone"
                    }
                  >
                    {course.map((data, index) => {
                      return (
                        <li key={index}>
                          {data}
                          <a onClick={(e: any) => removeCourse(e, data)}>
                            <sup>
                              <i className="fa fa-close"></i>
                            </sup>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <!-- WIDGET --> */}

                {/* <CourseFilter
                  course={course}
                  setcourse={setcourse}
                  courseid={courseid}
                  setcourseid={setcourseid}
                /> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5>
                    <span onClick={hendlecountryOpne}>
                      filter by <b>course</b>
                      {countryOpne == true ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={countryOpne == true ? "countryHide" : ""}>
                    {countryOpne == false &&
                      activecourse &&
                      activecourse?.map((data: any, i: any) => {
                        if (data?.status == 1)
                          return (
                            <li key={i}>
                              <label>
                                <input
                                  type="checkbox"
                                  name={data?.title}
                                  value={data?.id}
                                  checked={courseid.includes(
                                    data?.id.toString()
                                  )}
                                  onChange={(e: any) => hendlClickCourse(e)}
                                />
                                <i className="awe-icon awe-icon-check"></i>
                                {data?.title?.length > 20
                                  ? `${data?.title.substring(0, 20)}...`
                                  : data?.title}
                              </label>
                            </li>
                          );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
              <div className="banner-section1">
                <VerticalAddSlider />
              </div>
            </div>
            <div className="col-lg-10 col-md-push-2">
              <div className="filter-page__content">
                <div className="filter-item-wrapper">
                  {/* <!-- ITEM --> */}
                  {seo?.header_content && (
                    <div className="about-uni-text shade1">
                      <SeoHeaderContent />
                    </div>
                  )}
                  <div className="course-offer-uni shade1 pb-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="headingmains text-center"></div>
                        <div className="all-center sub-section pb-0 pt-0">
                          <div className="row">
                            <div className="col-md-9">
                              <div className="lavelbox text-left">
                                <b>level of study</b>
                                <input
                                  type="radio"
                                  name="RadioGroup1"
                                  value="1"
                                  onClick={(e: any) =>
                                    levelofstudy(e.target.value)
                                  }
                                  id="RadioGroup1_0"
                                  defaultChecked
                                />
                                <label htmlFor="RadioGroup1_0">
                                  {" "}
                                  bachelor's{" "}
                                </label>

                                <input
                                  type="radio"
                                  name="RadioGroup1"
                                  value="2"
                                  onClick={(e: any) =>
                                    levelofstudy(e.target.value)
                                  }
                                  id="RadioGroup1_1"
                                />
                                <label htmlFor="RadioGroup1_1"> master's</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="page-top pull-right mb-4">
                                <select
                                  className="awe-select"
                                  onChange={(e: any) =>
                                    sortByName(e.target.value)
                                  }
                                >
                                  <option>sort by</option>
                                  <option value="asc">lowest fees</option>
                                  <option value="desc">highest fees</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {universityCourse && universityCourse?.length == 0 ? (
                            <div className="nodataFound">
                              <img src="/assets/img/noDataFound.jpeg" alt="" />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            {universityCourse?.length > 0 &&
                              universityCourse?.map((data: any, index: any) => {
                                let date =
                                  data?.application_last_date != "0000-00-00" &&
                                  data?.application_last_date != null &&
                                  data?.application_last_date != "Invalid date"
                                    ? dateFormat(
                                        data?.application_last_date,
                                        "d-mmm-yyyy"
                                      )
                                    : "N/A";
                                const exam = JSON.parse(data?.exam_score);

                                return (
                                  <div className="col-md-6" key={index}>
                                    <div className="studywrp shade1">
                                      <div className="bgstudy">
                                        <div className="row ">
                                          <div className="col-sm-12 col-lg-7 text-left">
                                            <h6>
                                              {data?.university_course} <br />
                                              {data?.course_type == 1
                                                ? "full time"
                                                : data?.course_type == 2
                                                ? "part time"
                                                : data?.course_type == 3
                                                ? "weekend"
                                                : data?.course_type == 4
                                                ? "other"
                                                : ""}
                                            </h6>
                                          </div>
                                          <div className="col-sm-12 col-lg-5">
                                            <h5>
                                              <span>
                                                {data?.fee_currency_code}
                                              </span>{" "}
                                              {data?.first_yr_fee}/year
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="detailstudy">
                                        <div className="row">
                                          <div className="col-sm-12 col-lg-12 text-left">
                                            <table
                                              width="100%"
                                              style={{ border: "0" }}
                                              cellSpacing="0"
                                              cellPadding="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <table
                                                      width="100%"
                                                      style={{ border: "0" }}
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td rowSpan={2}>
                                                            <img
                                                              src="/assets/img/time.png"
                                                              alt=""
                                                              className=""
                                                            />
                                                          </td>
                                                          <td>
                                                            <b>
                                                              {data?.duration}
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <span>
                                                              course&nbsp;duration
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td>
                                                    <table
                                                      width="100%"
                                                      style={{ border: "0" }}
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td rowSpan={2}>
                                                            <img
                                                              src="/assets/img/date.png"
                                                              alt=""
                                                              className=""
                                                            />
                                                          </td>
                                                          <td>
                                                            <b>{date}</b>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <span>
                                                              application&nbsp;deadline
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td>
                                                    <table
                                                      width="100%"
                                                      style={{ border: "0" }}
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody className="course__">
                                                        <tr>
                                                          <td rowSpan={2}>
                                                            <img
                                                              src="/assets/img/library-building.png"
                                                              alt=""
                                                              className=""
                                                            />
                                                          </td>
                                                          <td>
                                                            {data?.entry_requirements ? (
                                                              indexMore ===
                                                                data?.id &&
                                                              data?.entry_requirements?.split(
                                                                " "
                                                              ).length <
                                                                readMore ? (
                                                                <p className="course__font">
                                                                  {
                                                                    data?.entry_requirements
                                                                  }
                                                                </p>
                                                              ) : (
                                                                <p className="course__font">
                                                                  {" "}
                                                                  {data?.entry_requirements
                                                                    ?.split(" ")
                                                                    .splice(
                                                                      0,
                                                                      5
                                                                    )
                                                                    .join(" ")}
                                                                  .{" "}
                                                                  <a
                                                                    className="read-more-new"
                                                                    href="#"
                                                                    onClick={(
                                                                      e: any
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      setreadMore(
                                                                        data?.entry_requirements?.split(
                                                                          " "
                                                                        )
                                                                          .length &&
                                                                          data?.entry_requirements?.split(
                                                                            " "
                                                                          )
                                                                            .length +
                                                                            1
                                                                      );
                                                                      setIndexMore(
                                                                        data?.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    read more
                                                                  </a>
                                                                </p>
                                                              )
                                                            ) : (
                                                              <p></p>
                                                            )}
                                                            {/* we can use it easy way*/}
                                                            {/* <p className={indexMore === data?.id ? "course__font" : "course__font turncate1"}>{data?.entry_requirements}</p>
                                                              {indexMore === data?.id ? null : 
                                                              <a className="read-more-new" href="#" onClick={(e:any) => {e.preventDefault(); setIndexMore(data?.id);}}>read more</a>
                                                              } */}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <span>
                                                              entry&nbsp;requirement
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div className="examscore">
                                              {/* exam scores &nbsp;{" "}
                                              {typeof exam == "object"
                                                ? exam?.map(
                                                    (datas: any, i: any) => {
                                                      return (
                                                        <span key={i}>
                                                          {" " + datas?.title}{" "}
                                                          <b>{datas?.value}</b>{" "}
                                                          {i > 0 && i - 1
                                                            ? ""
                                                            : "|"}
                                                        </span>
                                                      );
                                                    }
                                                  )
                                                : ""} */}
                                              exam scores &nbsp; IELTS{" "}
                                              <b>
                                                {data?.iselts_score
                                                  ? data?.iselts_score
                                                  : 0}
                                              </b>{" "}
                                              | TOEFL{" "}
                                              <b>
                                                {data?.toefl_scrore
                                                  ? data?.toefl_scrore
                                                  : 0}
                                              </b>{" "}
                                              | PTE{" "}
                                              <b>
                                                {data?.pte_score
                                                  ? data?.pte_score
                                                  : 0}
                                              </b>
                                            </div>
                                            <div className="option">
                                              <span>options available</span>
                                              M.B.A Entrepreneurship
                                            </div>
                                            <div className="option">
                                              Offered by{" "}
                                              <p className="color-green">
                                                {
                                                  data?.ad_university
                                                    ?.university_name
                                                }
                                                {/* Harvard Business School */}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-12 col-lg-3">
                                            {/* <a href="" className="checkbtn">
                                              check eligibility
                                            </a> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            {/* <div className="col-12">
                              <AdSlider />
                            </div> */}
                          </div>
                          {universitycourse?.length == 50 ? (
                            <div className="all-center my-2 ">
                              <a
                                onClick={(e) => {
                                  hendleLodemore(e);
                                }}
                                className="view-more readbtn"
                              >
                                Load More
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {seo?.footer_content && (
                    <div className="about-uni-text shade1">
                      <SeoFooterContent />
                    </div>
                  )}
                  {/* <!-- END / ITEM --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Program;
