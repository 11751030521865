import React from "react";
import { Redirect, Route, RouterProps, Switch } from "react-router-dom";
import ApplyNow from "../Component/ApplyNow/ApplyNow";
import Blog from "../Component/Blog/Blog";
import BlogDetails from "../Component/Blog/BlogDetails";

import CoachDetails from "../Component/CounsellorList/CoachDetails";
import PaymentVerification from "../Component/CounsellorList/PaymentVerification";
import Coaching from "../Component/CounsellorList/Coaching";
import ThankYou from "../Component/CounsellorList/ThankYou";
import CounsellorAndCoach from "../Component/CounsellorList/CounsellorAndCoach";
import CounsellorDetails from "../Component/CounsellorList/CounsellorDetails";
import CounsellorList from "../Component/CounsellorList/CounsellorList";
import Country from "../Component/country/Country";
import AboutCourse from "../Component/Course/AboutCourse";
import Career from "../Component/Career/Career";
import Course from "../Component/Course/Course";
import DiscussionDetails from "../Component/DiscussionList/DiscussionDetails";
import DiscussionList from "../Component/DiscussionList/DiscussionList";
import TermsAndConditions from "../Component/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../Component/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "../Component/ContactUs/ContactUs";
import Accommodation from "../Component/Service/Accommodation";
import Counsellor from "../Component/Service/Counsellor";
import Alumni from "../Component/Service/Alumni";
import FreeApp from "../Component/Service/FreeApp";
import Scholarship from "../Component/Service/Scholarship";
import InternshipPlacement from "../Component/Service/InternshipPlacement";
import IELTS from "../Component/Service/IELTS";
import GRE from "../Component/Service/GRE";
import SAT from "../Component/Service/SAT";
import PTE from "../Component/Service/PTE";
import TOEFL from "../Component/Service/TOEFL";
import GMAT from "../Component/Service/GMAT";
import Exams from "../Component/Service/Exams";
import Faqs from "../Component/Faqs/Faqs";
import Packages from "../Component/Packages/Packages";
import Profile from "../Component/Profile/Profile";
import PaymentHistory from "../Component/Profile/PaymentHistory";
import VerifyEmail from "../Component/Profile/VerifyEmail";
import VerifyEmailVerification from "../Component/Profile/VerifyEmailVerification";
import Program from "../Component/Program/Program";
import Reviews from "../Component/Reviews/Reviews";
import Admission from "../Component/Admission/Admission";
import OffersScholarship from "../Component/Scholarship/OffersScholarship";
import ScholarshipDetails from "../Component/Scholarship/ScholarshipDetails";
import AboutUs from "../Component/AboutUs/AboutUs";
import AboutUniversity from "../Component/UniversitiesList/AboutUniversity";
import UniversitiesDetails from "../Component/UniversitiesList/UniversitiesDetails";
import UniversitiesList from "../Component/UniversitiesList/UniversitiesList";
import ApplyThankYou from "../Component/UniversitiesList/ApplyThankYou";
import Service from "../Component/Service/Service";
import Funding from "../Component/Service/Funding";
import Coach from "../Component/Service/Coach";
import Thankyou from "../Component/Service/Thankyou";
import Home from "../Home";
import SignIn from "../SignIn";
import CounsellorLogin from "../SignIn/CounsellorLogin";
import SignUp from "../SignUp";
import CounsellorRegister from "../SignUp/CounsellorRegister";
import LoginProtectRoute from "./LoginProtectRoute";
import ProtectedRoute from "./ProtectedRoute";
import UniversitiesInUK from "../Component/LandingPages/UniversitiesInUK";
import UniversitiesInUSA from "../Component/LandingPages/UniversitiesInUSA";
import UniversitiesInCanada from "../Component/LandingPages/UniversitiesInCanada";
import UniversitiesInIreland from "../Component/LandingPages/UniversitiesInIreland";
import UniversitiesInAustralia from "../Component/LandingPages/UniversitiesInAustralia";
import UniversitiesInNetherlands from "../Component/LandingPages/UniversitiesInNetherlands";
import UniversitiesInNewZealand from "../Component/LandingPages/UniversitiesInNewZealand";
import UniversitiesInSingapore from "../Component/LandingPages/UniversitiesInSingapore";
import PageNotFound from "../Component/PageNotFound/PageNotFound";
import PayRedirect from "../Component/PayRedirect/PayRedirect";
import ContactUsDetail from "../Component/ContactUs/ContactusDetail";

function AppRoute() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/ielts-details"
        render={(props: RouterProps) => <Redirect to="/ielts-exam" />}
      />
      <Route
        exact
        path="/gre-details"
        render={(props: RouterProps) => <Redirect to="/gre-exam" />}
      />
      <Route
        exact
        path="/sat-details"
        render={(props: RouterProps) => <Redirect to="/sat-exam" />}
      />
      <Route
        exact
        path="/pte-details"
        render={(props: RouterProps) => <Redirect to="/pte-exam" />}
      />
      <Route
        exact
        path="/toefl-details"
        render={(props: RouterProps) => <Redirect to="/toefl-exam" />}
      />
      <Route
        exact
        path="/gmat-details"
        render={(props: RouterProps) => <Redirect to="/gmat-exam" />}
      />
      <Route
        exact
        path="/unidetails/bromley_college_of_further_and_higher_education/UKL00044/UK"
        render={(props: RouterProps) => (
          <Redirect to="/university/bromley-college-of-further-and-higher-education" />
        )}
      />
      {/* <Route exact path="/country/:slug" component={Country}/> */}
      <Route
        exact
        path="/country/:slug"
        render={(props: any) => {
          const slug: string = props?.match?.params?.slug;
          const incText: string = "study-in-";
          if (slug?.includes(incText)) {
            props?.history?.push(`/country/${slug?.replace(incText, "")}`);
          } else {
            return <Country />;
          }
        }}
      />
      <LoginProtectRoute exact path="/login" component={SignIn} />
      <Route exact path="/register" component={SignUp} />
      <Route exact path="/counselloer" component={CounsellorList} />
      <Route
        exact
        path="/counsellor-and-mentor"
        component={CounsellorAndCoach}
      />
      <Route exact path="/counsellor/:slug" component={CounsellorDetails} />
      <Route exact path="/mentor/:slug" component={CoachDetails} />
      <Route
        exact
        path="/offers-and-scholarship"
        component={OffersScholarship}
      />
      <Route
        exact
        path="/:country?/:level?/universities"
        component={UniversitiesList}
      />
      {/* <Route exact path="/universitiesdetails" component={UniversitiesDetails} /> */}
      <Route
        exact
        path="/:country?/:level?/university/:slug"
        component={AboutUniversity}
      />
      <Route
        exact
        path="/scholarshipdetails/:slug"
        component={ScholarshipDetails}
      />
      <Route exact path="/packages" component={Packages} />
      <Route exact path="/program" component={Program} />
      <Route exact path="/reviews" component={Reviews} />
      <Route exact path="/admission" component={Admission} />
      <Route exact path="/faqs" component={Faqs} />
      <Route exact path="/discussions" component={DiscussionList} />
      <Route
        exact
        path="/about-discussion/:slug"
        component={DiscussionDetails}
      />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/blog/:slug" component={BlogDetails} />
      <Route exact path="/coaching" component={Coaching} />
      <ProtectedRoute exact path="/applynow" component={ApplyNow} />
      <Route exact path="/counsellorlogin" component={CounsellorLogin} />
      <Route exact path="/counsellorregister" component={CounsellorRegister} />
      <Route exact path="/profile/:slug/" component={Profile} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/email-verification/:token" component={VerifyEmail} />
      <Route
        exact
        path="/email-verification-expired"
        component={VerifyEmailVerification}
      />
      {/* <Route exact path="/country/:slug" component={Country} /> */}
      <Route exact path="/course" component={Course} />
      <Route exact path="/course/:slug/:level" component={AboutCourse} />
      <Route exact path="/terms-conditions" component={TermsAndConditions} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/accommodation" component={Accommodation} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/career" component={Career} />
      <Route exact path="/services" component={Service} />
      <Route exact path="/funding" component={Funding} />
      <Route exact path="/mentor" component={Coach} />
      <Route exact path="/counsellor" component={Counsellor} />
      <Route exact path="/scholarship" component={Scholarship} />
      <Route exact path="/test-preparation" component={Exams} />
      <Route
        exact
        path="/internship-placement"
        component={InternshipPlacement}
      />
      <Route exact path="/ielts-exam" component={IELTS} />
      <Route exact path="/gre-exam" component={GRE} />
      <Route exact path="/sat-exam" component={SAT} />
      <Route exact path="/pte-exam" component={PTE} />
      <Route exact path="/toefl-exam" component={TOEFL} />
      <Route exact path="/gmat-exam" component={GMAT} />
      <Route exact path="/thank-you" component={Thankyou} />
      <Route exact path="/thank-you-page" component={ThankYou} />
      <Route exact path="/alumni-chat-details" component={Alumni} />
      <Route exact path="/free-admissify-common-app" component={FreeApp} />
      <Route exact path="/thank-you-apply" component={ApplyThankYou} />
      <Route exact path="/payment/verify" component={PaymentVerification} />
      <Route
        exact
        path="/location/:slug"
        component={ContactUsDetail}
      />
      <Route
        exact
        path="/top-universities-in-uk"
        component={UniversitiesInUK}
      />
      <Route
        exact
        path="/top-universities-in-usa"
        component={UniversitiesInUSA}
      />
      <Route
        exact
        path="/top-universities-in-canada"
        component={UniversitiesInCanada}
      />
      <Route
        exact
        path="/top-universities-in-ireland"
        component={UniversitiesInIreland}
      />
      <Route
        exact
        path="/top-universities-in-australia"
        component={UniversitiesInAustralia}
      />
      <Route
        exact
        path="/top-universities-in-netherland"
        component={UniversitiesInNetherlands}
      />
      <Route
        exact
        path="/top-universities-in-newzealand"
        component={UniversitiesInNewZealand}
      />
      <Route
        exact
        path="/top-universities-in-singapore"
        component={UniversitiesInSingapore}
      />
      <Route exact path="/pay/online/:id" component={PayRedirect} />
      {/* Page Not Fount Route */}
      <Route exact path="/page-not-found" component={PageNotFound} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

export default AppRoute;
