import { table } from "console";
import react from "react";
var monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const getQuarter = (d: any) => {
  var data = [];
  var firstYear = null;
  for (var j = 0; j < 13; j++) {
    if (j % 3 == 0) {
      var today = new Date();
      var todayM = today.getMonth();
      var dd = new Date(d.getFullYear(), j, 1);
      var m = dd.getMonth();
      var sMn = monthNames[Math.floor(dd.getMonth())];

      var y = dd.getFullYear();
      if (!firstYear) {
        firstYear = y;
      }
      if (m >= 11) {
        var nextq = new Date(dd.getFullYear() + 1, 1, 1);
      } else {
        var nextq = new Date(dd.getFullYear(), m + 2, 1);
      }
      var eMn = monthNames[nextq.getMonth()];
      var nextQy = nextq.getFullYear();
      if (dd.getFullYear() == today.getFullYear()) {
        if (todayM <= m) data.push([sMn, eMn, y, nextq]);
      } else {
        if (firstYear == nextQy) data.push([sMn, eMn, y, nextq]);
      }
    }
  }
  return data;
};
export const getQuarters = () => {
  var noOfYears = 2;
  var today = new Date();
  var qWiseData = [];
  for (var i = 1; i <= noOfYears; i++) {
    if (i == 1) {
      var year = today.getFullYear();
    } else {
      var year = today.getFullYear() + 1;
    }
    var date = new Date(year, 0, 1);
    var res = getQuarter(date);
    qWiseData.push(res);
  }
  return qWiseData;
};

export const sorting = (a: any, b: any, type = "-") => {
  var ax = [],
    bx = [];

  a?.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
    ax.push([$1 || Infinity, $2 || ""]);
  });
  b?.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
    bx.push([$1 || Infinity, $2 || ""]);
  });

  while (ax?.length && bx?.length) {
    var an = ax.shift();
    var bn = bx.shift();
    if (type === "-") var nn = bn[0] - an[0] || an[1].localeCompare(bn[1]);
    else var nn = bn[0] + an[0] || an[1].localeCompare(bn[1]);
    if (nn) return nn;
  }

  return ax.length - bx.length;
};
export function addChatWidget() {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.setAttribute("id", "ze-snippet");
  script.setAttribute(
    "src",
    "https://static.zdassets.com/ekr/snippet.js?key=f4629f23-281b-407f-85c5-3fca370c39ba"
  );
  document.head.appendChild(script);
}

export function removeChatWidget() {
  document.getElementById("ze-snippet")?.remove();
}

// export function addInlineStylesToHtml(htmlString: any) {
//   // Create a temporary DOM element to hold the HTML string

//   const styles: any = {
//     h1: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em;",
//     h2: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em;",
//     h3: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em;",
//     h4: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em;",
//     h5: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em;",
//     h6: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em;",

//     p: "margin-top: 0.5em; margin-bottom: 0.5em; font-size:1.2em;",
//     td: "padding-left: 0.5em",

//     li: "padding-left: 1em",
//     ol: "padding-left: 2em",
//   };

//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlString, "text/html");

//   // Function to recursively apply styles to all elements
//   function applyStyles(element: any) {
//     // Apply styles to the current element
//     if (styles[element.tagName.toLowerCase()]) {
//       element.style.cssText += styles[element.tagName.toLowerCase()];
//     }

//     // Recursively apply styles to child elements
//     Array.from(element.children).forEach(applyStyles);
//   }

//   // Apply styles to the root element
//   applyStyles(doc.body);

//   // Serialize the modified DOM back to an HTML string
//   return new XMLSerializer().serializeToString(doc.body);
// }

export function addInlineStylesToHtml(htmlString: any) {
  // Define your styles
  const styles: any = {
    h1: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em; color:#FBB415;",
    h2: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em; color:#FBB415;",
    h3: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em; color:#FBB415;",
    h4: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em; color:#FBB415;",
    h5: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em; color:#FBB415;",
    h6: "margin-top: 1em; margin-bottom: 1em; font-size:1.5em; color:#FBB415;",
    p: "margin-top: 0.5em; margin-bottom: 0.5em; font-size:1.2em; ",
    td: "padding-left: 0.5em",
    li: "padding-left: 1em; white-space:unset;",
    ol: "padding-left: 2em",
  };

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to recursively apply styles to all elements
  function applyStyles(element: any) {
    // Apply styles to the current element
    if (styles[element.tagName.toLowerCase()]) {
      element.style.cssText += styles[element.tagName.toLowerCase()];
    }

    // Apply styles to parent of table
    if (element.tagName.toLowerCase() === "table") {
      const parentDiv = element.parentElement;
      if (parentDiv) {
        parentDiv.style.maxWidth = "100%";
        parentDiv.style.overflowX = "auto";
        parentDiv.style.overflowY = "auto";
      }
    }

    // Recursively apply styles to child elements
    Array.from(element.children).forEach(applyStyles);
  }

  // Apply styles to the root element
  applyStyles(doc.body);

  // Serialize the modified DOM back to an HTML string
  return new XMLSerializer().serializeToString(doc.body);
}
